export enum LoggingEvents {
    // Meadow Admin
    LOADED_MEADOW_ADMIN = "Loaded Meadow Admin",
    CREATED_SCHOOL = "Created New School",
    OPENED_SCHOOL_DETAILS = "Opened School Details",

    // Auth
    LOGIN = "Login Click",
    LOGOUT = "Logged Out",
    AUTH0_DROP_OFF = "Dropped off Auth0 Popup",

    //Language
    CHANGED_LANGUAGE = "Changed Language",

    // Onboarding
    STARTED_ONBOARDING = "Started Onboarding",
    FINISHED_ONBOARDING = "Finished Onboarding",
    COMPLETED_ONBOARDING_STEP = "Completed Onboarding Step",
    ANSWER_GUIDE_VIEW = "Answer Guide View",

    // Review and refine
    LOADED_CALCULATOR = "Loaded Net Price Calculator",
    COMPLETED_CALCULATOR = "Finished Net Price Calculator",
    CHANGE_NET_PRICE_PERIOD = "Net Price Period Changed",
    CALCULATOR_CONTAINER = "Calculator Container Modified",
    WAYS_TO_COVER = "Ways to Cover",
    VA_BENEFITS = "VA Benefits",
    NEXT_STEPS = "Next Steps",

    // Disclaimer
    DISCLAIMER_ACTION = "Disclaimer Action",
    // MOBILE
    MOBILE_ACTION = "Mobile Action",

    // DTC
    DTC_SCHOOL_VIEW = "School View",
    DTC_SCHOOL_SEARCH = "School Search",
    DTC_SAVE_TO_MY_SCHOOL = "Save to My School",
    DTC_REMOVE_FROM_MY_SCHOOL = "Remove from My School",

    // Share to School
    SHARE_TO_SCHOOL = "Share to School",
}

export enum LoggingActivityProperties {
    ONBOARDING_PAGE = "Onboarding Page",
    NEXT_ACTION = "Page Next Action",
    ONBOARDING_CHANGE_LOG = "Change Log",
    ONBOARDING_TOTAL_ACTIONS_COUNT = "Total Number of Actions",
    ANSWER_GUIDE_TITLE = "Answer Guide Title",

    CURRENT_LANGUAGE = "Current Language",

    DISCLAIMER_ACTION = "Action",
    PREVIOUS_NET_PRICE_PERIOD = "Previous Net Price Period",
    CURRENT_NET_PRICE_PERIOD = "Current Net Price Period",
    CALCULATOR_CONTAINER = "Calculator Container Name",
    CALCULATOR_CHANGE_LOG = "Change Log",
    CALCULATOR_COST_OF_ATTENDANCE = "Cost Of Attendance",
    WAYS_TO_COVER_TYPE = "Type",
    WAYS_TO_COVER_NAME = "Name",
    VA_BENEFITS_NAME = "Name",
    NEXT_STEPS_ACTION = "Action",
    NEXT_STEPS_NAME = "Name",

    EFC_CALCULATED = "EFC Calculated",
    SAI_CALCULATED = "SAI Calculated",
    PELL_TYPE = "Pell Type",
    ESTIMATE_COA = "Estimate: Cost of Attendance",
    ESTIMATE_PELL = "Estimate: Pell Grant",
    ESTIMATE_STATE_GRANT = "Estimate: State Grant",
    ESTIMATE_INSTITUTIONAL_GRANT = "Estimate: Institutional Grant",
    ESTIMATE_MERIT_AID = "Estimate: Merit Aid",
    ESTIMATE_MILITARY_BENEFITS = "Estimate: Military Benefits",
    ESTIMATE_NET_PRICE = "Estimate: Net Price",

    MOBILE_INTERACTION_ACTION = "Action",

    MEADOW_PRODUCT = "Product",

    LANGUAGE = "Language",

    DTC_SCHOOL_ID = "School ID",
    DTC_SCHOOL_NAME = "School Name",
    DTC_SEARCH_STRING = "Search String",
    DTC_MY_SCHOOL_ACTION_PAGE = "Action Page",
}

export enum LoggingPersonProperties {
    STUDENT_DATA_EFC = "EFC",
    STUDENT_DATA_SAI = "SAI",
    STUDENT_DATA_DEPENDENCY = "Dependency",
    STUDENT_DATA_HOUSEHOLD_SIZE = "Household Size",
    STUDENT_DATA_HOUSEHOLD_STUDENTS_SIZE = "Household Students Size",
    STUDENT_DATA_AGE = "Age",
    STUDENT_FINISHED_ONBOARDING = "Finished Onboarding",
    STUDENT_DATA_STATE = "Student State",
    // DTC
    DTC_MY_SCHOOL_LIST = "My Schools List",
    DTC_SCHOOLS_VIEWED = "Schools Viewed",
    // Misc
    INTERCOM_ID = "Intercom User Id",
}

export enum DisclaimerAction {
    OPENED = "Opened",
    ACCEPTED = "Accepted",
    CANCELLED = "Cancelled",
}

export enum MobileInteraction {
    OPEN_ANSWER_GUIDE = "Open Answer Guide",
    OPEN_ESTIMATE_DRAWER = "Open Estimate Drawer",
}

export enum AuthLoginFrom {
    HOME_LOGIN = "Home Page Login",
    OLD_ESTIMATE = "I Already Have an Estimate",
    SAVE_PROGRESS = "Save Progress",
    SAVE_ESTIMATE = "Save Estimate (review page)",
}

export enum WaysToCoverType {
    LOANS = "Loans",
    WORK_STUDY = "Work Study",
    SCHOLARSHIPS = "Scholarships",
    VA_BENEFITS = "VA Benefits",
}
