// export const HEADER_HEIGHT = 120; // px
export const HEADER_HEIGHT = 96; // px
export const DTC_HEADER_HEIGHT = 100; // px
export const FORM_HEADER_HEIGHT = 144; // px

export const MOBILE_HEADER_HEIGHT = 64; // px

export const CONTAINER_WITH_SIDEMENU_PADDING = 24; // px

export const STEPPER_HEAD_HEIGHT = 81; // px
export const STEPPER_HEAD_PADDING = 24; // px

export const STEPPER_FOOT_HEIGHT = 90; // px

export const STEPPER_FOOT_PADDING = 24; // px

export const MA_TOP_SECTION_HEIGHT = 45;

export const DTC_ONBOARDING_WIDTH = 800;
export const SPARKLE_GREEN_LEFT = "calc(50% - 22.2px/2 - 240px)";
export const SPARKLE_GREEN_TOP = "calc(50% - 22.2px/2 - 252.5px)";
