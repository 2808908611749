import { lazy } from "react";
// use import instead of lazy load due to bug on iPhone browsers
import MobileSearchSchools from "./DTC/MobileSearchSchools";
import ErrorPage from "./ErrorPage";

const Home = lazy(() => import("./Home"));
const Logout = lazy(() => import("./Logout"));
const CalculatorDetails = lazy(() => import("./MeadowAdmin/CalculatorDetails"));
const FormulaDetails = lazy(() => import("./MeadowAdmin/FormulaDetails"));
const NewSchool = lazy(() => import("./MeadowAdmin/NewSchool"));
const SchoolDetails = lazy(() => import("./MeadowAdmin/SchoolDetails"));
const SchoolsList = lazy(() => import("./MeadowAdmin/SchoolsList"));
const NetPriceCalculator = lazy(() => import("./NetPriceCalculator"));
const Onboarding = lazy(() => import("./Onboarding"));
const ReviewPage = lazy(() => import("./Review"));
const Theme = lazy(() => import("./Theme"));
const SearchSchools = lazy(() => import("./DTC/SearchSchools"));
const SavedSchools = lazy(() => import("./DTC/SavedSchools"));
const Estimate = lazy(() => import("./DTC/Estimate"));
const SchoolAbout = lazy(() => import("./DTC/SchoolAbout"));
const DTCOnboarding = lazy(() => import("./DTC/Onboarding"));

export {
    NetPriceCalculator,
    Home,
    Onboarding,
    Theme,
    ErrorPage,
    ReviewPage,
    Logout,
    SchoolsList,
    NewSchool,
    SchoolDetails,
    CalculatorDetails,
    FormulaDetails,
    SearchSchools,
    MobileSearchSchools,
    SavedSchools,
    Estimate,
    SchoolAbout,
    DTCOnboarding,
};
