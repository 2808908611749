import { lazy, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { RouteNames } from "../enums/routes";
import {
    CalculatorDetails,
    DTCOnboarding,
    ErrorPage,
    Estimate,
    FormulaDetails,
    Home,
    Logout,
    MobileSearchSchools,
    NetPriceCalculator,
    NewSchool,
    Onboarding,
    ReviewPage,
    SavedSchools,
    SchoolAbout,
    SchoolDetails,
    SchoolsList,
    SearchSchools,
} from "../pages";
import { Logging } from "../utils/Logging";
import VerifyDTCStudent from "./middlewares/VerifyDTCStudent";
import DTC from "./wrappers/DTC";
import Main from "./wrappers/Main";

const LoginAdmin = lazy(() => import("../pages/playground/LoginAdmin"));
const LoginStudent = lazy(() => import("../pages/playground/LoginStudent"));
const Debug = lazy(() => import("../pages/playground/Debug"));
const VerifyCalculatorType = lazy(() => import("./middlewares/VerifyCalculatorType"));
const VerifyMeadowAdmin = lazy(() => import("./middlewares/VerifyMeadowAdmin"));
const VerifySchool = lazy(() => import("./middlewares/VerifySchool"));
const AdminLayout = lazy(() => import("./wrappers/AdminLayout"));
const VerifyStudentAuth = lazy(() => import("./middlewares/VerifyStudentAuth"));
const VerifyDTCSchool = lazy(() => import("./middlewares/VerifyDTCSchool"));

const getDevRoutes = () => {
    return (
        <>
            <Route path={RouteNames.PLAYGROUND_LOGIN_ADMIN} element={<LoginAdmin />} />
            <Route path={RouteNames.PLAYGROUND_LOGIN_STUDENT} element={<LoginStudent />} />
            <Route path={RouteNames.PLAYGROUND_DEBUG} element={<Debug />} />
        </>
    );
};

const AppRoutes = () => {
    const location = useLocation();

    const [intercomLoaded, setIntercomLoaded] = useState<boolean>(false);

    useEffect(() => {
        // each time the location changes, let's call the update on intercom to let them know
        // https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
        if ("Intercom" in window) {
            (window as any).Intercom("update", {
                last_request_at: parseInt(new Date().getTime() / 1000 + ""),
                mixpanel_distinct_id: Logging.getDistinctId(),
            });
            if (!intercomLoaded) {
                const intercomId = (window as any).Intercom("getVisitorId");
                if (intercomId) {
                    Logging.setIntercomId(intercomId);
                    setIntercomLoaded(true);
                }
            }
        }
    }, [location.pathname]);

    return (
        <Routes>
            <Route element={<Main />}>
                <Route path={RouteNames.SELF} element={<Home />} />
                <Route path={RouteNames.LOGOUT} element={<Logout />} />

                <Route path={RouteNames.MEADOW_ADMIN} element={<VerifyMeadowAdmin />}>
                    {getDevRoutes()}
                    <Route element={<AdminLayout />}>
                        <Route index element={<SchoolsList />} />
                        <Route path={RouteNames.NEW_SCHOOL} element={<NewSchool />} />
                        <Route path={RouteNames.SCHOOL_DETAILS} element={<SchoolDetails />} />
                        <Route
                            path={RouteNames.CALCULATOR_DETAILS}
                            element={<CalculatorDetails />}
                        />
                        <Route path={RouteNames.FORMULA_DETAILS} element={<FormulaDetails />} />
                    </Route>
                </Route>

                <Route path={RouteNames.DTC} element={<DTC />}>
                    <Route element={<VerifyDTCStudent />}>
                        <Route index element={<SearchSchools />} />
                        <Route path={RouteNames.MOBILE_SEARCH} element={<MobileSearchSchools />} />
                        <Route path={RouteNames.SAVED_SCHOOLS} element={<SavedSchools />} />
                        <Route path={RouteNames.ONBOARDING} element={<DTCOnboarding />} />
                        <Route
                            path={RouteNames.CALCULATOR}
                            element={<Navigate to={"../"} replace />}
                        />
                        <Route path=":unitid" element={<VerifyDTCSchool />}>
                            <Route index element={<Navigate to={RouteNames.ESTIMATE} replace />} />
                            <Route path={RouteNames.ABOUT} element={<SchoolAbout />} />
                            <Route path={RouteNames.ESTIMATE} element={<Estimate />} />
                            <Route
                                path={RouteNames.ANY}
                                element={<Navigate to={RouteNames.ESTIMATE} replace />}
                            />
                        </Route>
                    </Route>
                </Route>

                <Route path="/:schoolId" element={<VerifySchool />}>
                    <Route element={<VerifyStudentAuth />}>
                        <Route index element={<Home />} />

                        <Route
                            path={`${RouteNames.CALCULATOR_CONFIG}/:calculatorId`}
                            element={<VerifyCalculatorType />}
                        >
                            <Route index element={<Home />} />
                            <Route path={RouteNames.ONBOARDING} element={<Onboarding />} />
                            <Route path={RouteNames.CALCULATOR} element={<NetPriceCalculator />} />
                            <Route path={RouteNames.REVIEW} element={<ReviewPage />} />
                        </Route>

                        <Route path=":calculatorId" element={<VerifyCalculatorType />}>
                            <Route index element={<Home />} />
                            <Route path={RouteNames.ONBOARDING} element={<Onboarding />} />
                            <Route path={RouteNames.CALCULATOR} element={<NetPriceCalculator />} />
                            <Route path={RouteNames.REVIEW} element={<ReviewPage />} />
                        </Route>
                    </Route>
                </Route>
                <Route path={RouteNames.ANY} element={<ErrorPage />} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
