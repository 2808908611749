import { useAuth0 } from "@auth0/auth0-react";
import { ErrorBoundary } from "@sentry/react";
import { Suspense } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Preloader from "./components/custom/Preloader";
import { ErrorPage } from "./pages/index";
import AppRoutes from "./routes/AppRoutes";
import { Logging } from "./utils/Logging";

Logging.init();

const App = () => {
    const { getAccessTokenSilently } = useAuth0();

    if (process.env.NODE_ENV == "development") {
        // this is used in development mode to be able to get the auth0 token in the console
        // we just need to run the function "getToken()" and the token is printed out
        (window as any).getToken = () => {
            getAccessTokenSilently().then((token) => {
                console.log(token);
            });
        };
    }

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="The easiest way to estimate your education costs."
                />
            </Helmet>
            <Suspense fallback={<Preloader />}>
                <ErrorBoundary
                    showDialog={true}
                    fallback={({ error }) => {
                        const isChunkLoadError = /ChunkLoadError/.test(error.name);

                        return (
                            <BrowserRouter>
                                <ErrorPage
                                    isChunkLoadError={isChunkLoadError}
                                    apiError={{ code: 500, message: "" }}
                                />
                            </BrowserRouter>
                        );
                    }}
                >
                    <BrowserRouter>
                        <AppRoutes />
                    </BrowserRouter>
                </ErrorBoundary>
            </Suspense>
        </>
    );
};

export default App;
