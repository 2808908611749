import { DTCSchool } from "@meadow-fi/price-libraries/interfaces/dtc-school";
import { Student } from "@meadow-fi/price-libraries/interfaces/student";
import cloneDeep from "lodash.clonedeep";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext, UserReducerActionTypes } from "../contexts/user";
import { LocalStorageKeys } from "../enums/localStorage";
import { Logging } from "../utils/Logging";
import useLocalStorage from "./useLocalStorage";

export type UseDTCGuestProps = {
    updateSavedSchoolsCb?: ({
        prevSavedSchools,
        nextSavedSchools,
        unitid,
    }: {
        prevSavedSchools: number[];
        nextSavedSchools: number[];
        unitid: number;
    }) => void;
};

const useDTCGuest = (props?: UseDTCGuestProps) => {
    const { get, set } = useLocalStorage();
    const { dispatch: userDispatch } = useContext(UserContext);
    const location = useLocation();

    const [savedSchools, setSavedSchools] = useState<number[]>(() => {
        const saved = get(LocalStorageKeys.SAVED_SCHOOLS) as number[];
        return saved && Array.isArray(saved) ? saved : [];
    });

    useEffect(() => {
        set(LocalStorageKeys.SAVED_SCHOOLS, savedSchools);
    }, [savedSchools]);

    const addToSavedSchools = (unitid: number) => {
        const nextSavedSchools = cloneDeep(savedSchools);
        const index = nextSavedSchools.indexOf(unitid);
        if (index === -1) {
            nextSavedSchools.push(unitid);
            Logging.dtcSaveSchoolToList(unitid, "", "NPCDoorway", nextSavedSchools);
        }
        userDispatch({ type: UserReducerActionTypes.SET_SAVED_SCHOOLS, payload: nextSavedSchools });
        setSavedSchools((prevSavedSchools) => {
            if (prevSavedSchools.indexOf(unitid) === -1) {
                return [...prevSavedSchools, unitid];
            } else {
                return [...prevSavedSchools];
            }
        });
    };

    const updateSavedSchools = (school: Partial<DTCSchool>) => {
        const prevSavedSchools = get(LocalStorageKeys.SAVED_SCHOOLS);
        const nextSavedSchools = cloneDeep(prevSavedSchools);
        const index = nextSavedSchools.indexOf(school.unitid);

        if (index === -1) {
            nextSavedSchools.push(school.unitid);
            Logging.dtcSaveSchoolToList(
                school.unitid!,
                school.details!.name,
                location.pathname.includes("/estimate") ? "MyPlan" : "Browse",
                nextSavedSchools
            );
        } else {
            Logging.dtcRemoveSchoolFromList(school.unitid!, school.details!.name, nextSavedSchools);
            nextSavedSchools.splice(index, 1);
        }

        userDispatch({ type: UserReducerActionTypes.SET_SAVED_SCHOOLS, payload: nextSavedSchools });
        setSavedSchools(nextSavedSchools);
        props?.updateSavedSchoolsCb?.({
            prevSavedSchools,
            nextSavedSchools,
            unitid: school.unitid!,
        });
    };

    const saveStudent = (student: Student) => {
        set(LocalStorageKeys.STUDENT, student);
    };

    const readStudent = (): Student | null => {
        return get(LocalStorageKeys.STUDENT);
    };

    return { savedSchools, updateSavedSchools, saveStudent, readStudent, addToSavedSchools };
};

export default useDTCGuest;
