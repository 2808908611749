import { Close } from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    FilledInput,
    GlobalStyles,
    InputAdornment,
    List,
    SxProps,
    Typography,
} from "@mui/material";
import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import SearchListItem from "../../components/custom/Header/SearchListItem";
import { DEBOUNCE } from "../../constants/dtcSearch";
import useDTCSearch from "../../hooks/useDTCSearch";
import { BACKGROUND_DEFAULT, BLACK, BLACK_500, DS_V2_GREY_100 } from "../../theme/colors";

const boxSx: SxProps = {
    boxSizing: "border-box",
    left: 0,
    height: "100vh",
    borderRadius: 0,
    overflowY: "hidden",
    background: BACKGROUND_DEFAULT,
};

const PAGE_SIZE = 10;
const MobileSearchSchools = () => {
    const navigate = useNavigate();
    const { search, loadMore, searchValue, clear, isLoading, isLoadingMore, results, pagination } =
        useDTCSearch();

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            clear();
        } else {
            search({ search: e.target.value, size: PAGE_SIZE.toString() }, DEBOUNCE);
        }
    };

    const handleLoadMore = async () => {
        if (pagination?.hasMorePages) {
            loadMore({
                search: searchValue,
                size: PAGE_SIZE.toString(),
                page: (pagination.currentPage + 1).toString(),
            });
        }
    };

    const LoadingBox = () => (
        <Box sx={{ textAlign: "center", pb: 3 }}>
            <CircularProgress variant="indeterminate" size={20} />
        </Box>
    );

    const ResultsComponent = useMemo(() => {
        if (isLoading) {
            return <LoadingBox />;
        }

        if (!results.length) {
            return (
                <Typography variant="body1" sx={{ pt: 2 }}>
                    {searchValue ? "The search has returned no results" : "Type for results"}
                </Typography>
            );
        }

        return (
            <>
                <List sx={{ pt: 4 }}>
                    {results.map((school) => {
                        return (
                            <SearchListItem
                                key={school.unitid!}
                                school={school}
                                searchKey={searchValue}
                                sx={{ pl: 0 }}
                            ></SearchListItem>
                        );
                    })}
                </List>
                {isLoadingMore && <LoadingBox />}
            </>
        );
    }, [isLoading, isLoadingMore, searchValue, results]);

    const LoadMoreComponent = useMemo(() => {
        if (!isLoading && !isLoadingMore && pagination?.hasMorePages) {
            return (
                <Button sx={{ width: "100%" }} variant="primary" onClick={handleLoadMore}>
                    Load more
                </Button>
            );
        }
    }, [isLoading, isLoadingMore, pagination]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            (document.activeElement as any).blur();
        }
    };

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <Box sx={boxSx}>
                <GlobalStyles
                    styles={{
                        body: { overflow: "hidden" },
                    }}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        overflow: "visible",
                    }}
                >
                    <FilledInput
                        autoFocus
                        disableUnderline={true}
                        placeholder="Search schools by name"
                        fullWidth
                        color="primary"
                        value={searchValue}
                        onKeyDown={handleKeyDown}
                        onChange={handleSearchChange}
                        sx={{
                            width: "100vw",
                            background: `${DS_V2_GREY_100} !important`,
                            height: 80,
                            color: BLACK_500,
                            borderRadius: 0,
                            padding: "16px 32px",
                            position: "fixed",
                            zIndex: 1,
                            "& input::placeholder": {
                                color: BLACK_500,
                            },
                        }}
                        inputProps={{ style: { fontSize: "1rem" } }}
                        startAdornment={
                            <InputAdornment position="start">
                                <img
                                    src="/assets/images/back-icon.svg"
                                    onClick={() => navigate(-1)}
                                />
                            </InputAdornment>
                        }
                        endAdornment={
                            searchValue ? (
                                <InputAdornment position="end">
                                    <Close sx={{ color: BLACK }} onClick={clear} />
                                </InputAdornment>
                            ) : null
                        }
                    />

                    <Box
                        sx={{
                            mt: 11,
                            pl: 4,
                            pr: 4,
                            pb: 2,
                            height: "calc(100% - 200px)",
                            overflowY: "auto",
                        }}
                        onScroll={(event) => {
                            if ((event.target as any)?.scrollTop > 100) {
                                (document.activeElement as any).blur();
                            }
                        }}
                    >
                        {ResultsComponent}
                        {LoadMoreComponent}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default MobileSearchSchools;
