import { DTCSchool } from "@meadow-fi/price-libraries/interfaces/dtc-school";
import { ListItem, ListItemText, SxProps, Typography } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../../enums/routes";
import { GREY_500, GREY_900, TYPOGRAPHY_80 } from "../../../theme/colors";

export interface SearchListItemProps {
    school: Partial<DTCSchool>;
    searchKey: string;
    sx: SxProps;
}

const SearchListItem = ({ school, searchKey, sx }: SearchListItemProps) => {
    const primaryText = school.details!.name;
    const primaryMatches = match(primaryText, searchKey, { insideWords: true });
    const primaryParts = parse(primaryText, primaryMatches);

    const secondaryText = `${school.details!.city}, ${school.details!.state}`;
    const secondaryMatches = match(secondaryText, searchKey, { insideWords: true });
    const secondaryParts = parse(secondaryText, secondaryMatches);

    const navigate = useNavigate();

    return (
        <ListItem
            key={school.unitid!}
            onClick={() => navigate(`/${RouteNames.DTC}/${school.unitid!}/${RouteNames.ESTIMATE}`)}
            sx={sx}
        >
            <ListItemText
                primary={
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: GREY_900,
                        }}
                    >
                        {primaryParts.map((part, idx) => (
                            <span
                                key={idx}
                                style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                    color: part.highlight ? TYPOGRAPHY_80 : GREY_900,
                                }}
                            >
                                {part.text}
                            </span>
                        ))}
                    </Typography>
                }
                secondary={
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: GREY_500,
                        }}
                    >
                        {secondaryParts.map((part, idx) => (
                            <span
                                key={idx}
                                style={{
                                    fontWeight:
                                        part.highlight && primaryMatches.length === 0 ? 700 : 400,
                                }}
                            >
                                {part.text}
                            </span>
                        ))}
                    </Typography>
                }
            />
        </ListItem>
    );
};

export default SearchListItem;
