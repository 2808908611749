/* eslint-disable @typescript-eslint/no-empty-function */
import { NetPricePeriod } from "@meadow-fi/price-libraries/enums/net-price";
import { DTCSchool } from "@meadow-fi/price-libraries/interfaces/dtc-school";
import { NetPriceEstimate } from "@meadow-fi/price-libraries/interfaces/net-price-estimate";
import { School } from "@meadow-fi/price-libraries/interfaces/school";
import { Student } from "@meadow-fi/price-libraries/interfaces/student";
import { EFCCalculatorFactory } from "@meadow-fi/price-libraries/utils/efc/EFCCalculatorFactory";
import { SAICalculatorFactory } from "@meadow-fi/price-libraries/utils/sai/SAICalculatorFactory";
import { StudentUtil } from "@meadow-fi/price-libraries/utils/StudentUtil";
import axios from "axios";
import flatten from "flat";
import cloneDeep from "lodash.clonedeep";
import mixpanel from "mixpanel-browser";
import { NPC_ROUTES } from "../constants/routes";
import { Language, MeadowProduct } from "../enums/all";
import {
    AuthLoginFrom,
    DisclaimerAction,
    LoggingActivityProperties,
    LoggingEvents,
    LoggingPersonProperties,
    MobileInteraction,
    WaysToCoverType,
} from "../enums/logging";
import { OnboardingButtonAction, OnboardingStateDataIds } from "../enums/onboarding";
import { AnswerGuideItem } from "../types/answerGuide.js";
import { AllSubStepIds } from "../types/onboarding";

type Dict = { [x: string]: any };

const host = process.env.REACT_APP_MEADOW_BACKEND_ENDPOINT;
const API_ROOT_URL = `${host}/meadow/v1`;

export class Logging {
    private static mixpanel() {
        if (process.env.REACT_APP_MIXPANEL_DISABLED) {
            // returns a dummy mixpanel object to avoid testing issues
            return {
                init: () => {},
                reset: () => {},
                track: () => {},
                time_event: () => {},
                identify: () => {},
                alias: () => {},
                register: () => {},
                get_distinct_id: () => "",
                people: { increment: () => {}, set: () => {}, union: () => {} },
            };
        }

        return mixpanel;
    }

    public static getDistinctId() {
        return this.mixpanel().get_distinct_id();
    }

    public static init() {
        this.mixpanel().init(process.env.REACT_APP_MIXPANEL_TOKEN!, {
            debug: process.env.NODE_ENV == "development",
        });
    }

    public static setIntercomId(intercomId: string) {
        const mixpanelData = [
            {
                $token: process.env.REACT_APP_MIXPANEL_TOKEN,
                $distinct_id: this.mixpanel().get_distinct_id(),
                $set: {
                    [LoggingPersonProperties.INTERCOM_ID]: intercomId,
                },
            },
        ];

        axios.put(`${API_ROOT_URL}/mixpanel/profile-set`, mixpanelData);
    }

    public static reset() {
        mixpanel.reset();
    }

    public static track(eventName: LoggingEvents, props: Dict = {}) {
        this.mixpanel().track(eventName, props);
    }

    public static timeEvent(eventName: LoggingEvents) {
        this.mixpanel().time_event(eventName);
    }

    /**
     * To be called each time the page loads and we know the user email address
     */
    public static identify(email: string) {
        this.mixpanel().identify(email);
        this.mixpanel().people.set("$email", email);
    }

    /**
     * To be called **once** when user first provides their email address
     */
    public static alias(email: string) {
        this.mixpanel().alias(email);
        this.mixpanel().people.set("$email", email);
    }

    public static identifySchool(schoolId: string) {
        this.mixpanel().register({ schoolId });
    }

    public static login(props: { page?: any; from?: AuthLoginFrom }) {
        if (!NPC_ROUTES.includes(props.page)) {
            props.page = "home";
            if (!props.from) {
                // there's no props.from specified when logging in through the header button
                // because it can be on home page or on NPC routes, so we differentiate by the
                // props.page
                props.from = AuthLoginFrom.HOME_LOGIN;
            }
        } else if (!props.from) {
            props.from = AuthLoginFrom.SAVE_PROGRESS;
        }

        this.track(LoggingEvents.LOGIN, props);
    }

    public static logoutClick() {
        this.track(LoggingEvents.LOGOUT);
    }

    public static auth0PopupDropOff() {
        this.track(LoggingEvents.AUTH0_DROP_OFF);
    }

    public static startOnboarding() {
        this.track(LoggingEvents.STARTED_ONBOARDING);
        // track onboarding process time
        this.timeEvent(LoggingEvents.FINISHED_ONBOARDING);
    }

    public static finishOnboarding(student: Student, actionsCount: number) {
        // due to mixpanel logic, add a second delay to avoid request order being mixed up.
        setTimeout(() => this.finishOnboardingDelayed(student, actionsCount), 1000);
    }

    private static async finishOnboardingDelayed(student: Student, actionsCount: number) {
        const studentUtil: StudentUtil = new StudentUtil(student);
        student = cloneDeep(student);

        const [dependencyStatus, numberInHousehold, numberOfStudentsInHousehold, age] = [
            studentUtil.getDependencyStatus(),
            studentUtil.getNumberInHousehold(),
            studentUtil.getNumberOfStudentsInHousehold(),
            studentUtil.getStudentsAge(),
        ];
        const efcCalculated = EFCCalculatorFactory.create(student).calculate();
        const saiCalculated = SAICalculatorFactory.create(student).calculate();

        this.mixpanel().people.increment(LoggingEvents.FINISHED_ONBOARDING);
        this.mixpanel().people.set(
            LoggingPersonProperties.STUDENT_DATA_DEPENDENCY,
            dependencyStatus
        );
        this.mixpanel().people.set(LoggingPersonProperties.STUDENT_DATA_EFC, efcCalculated);
        this.mixpanel().people.set(LoggingPersonProperties.STUDENT_DATA_SAI, saiCalculated);
        this.mixpanel().people.set(
            LoggingPersonProperties.STUDENT_DATA_HOUSEHOLD_SIZE,
            numberInHousehold
        );
        this.mixpanel().people.set(
            LoggingPersonProperties.STUDENT_DATA_HOUSEHOLD_STUDENTS_SIZE,
            numberOfStudentsInHousehold
        );
        this.mixpanel().people.set(LoggingPersonProperties.STUDENT_FINISHED_ONBOARDING, true);
        this.mixpanel().people.set(
            LoggingPersonProperties.STUDENT_DATA_STATE,
            student.onboarding.personal.state ?? "International"
        );
        this.mixpanel().people.set(LoggingPersonProperties.STUDENT_DATA_AGE, age);
        (student.onboarding.personal.birthday as any) = this.convertDateToString(
            student.onboarding.personal.birthday
        );

        const data: Dict = flatten({
            ...student.onboarding,
            [LoggingActivityProperties.EFC_CALCULATED]: efcCalculated,
            [LoggingActivityProperties.SAI_CALCULATED]: saiCalculated,
            [LoggingActivityProperties.PELL_TYPE]: student.onboarding.financial.pellType,
        });
        data[LoggingActivityProperties.ONBOARDING_TOTAL_ACTIONS_COUNT] = actionsCount;
        this.track(LoggingEvents.FINISHED_ONBOARDING, data);

        if (!student.email) {
            // if user is anonymous
            const mixpanelData = [
                {
                    $token: process.env.REACT_APP_MIXPANEL_TOKEN,
                    $distinct_id: this.mixpanel().get_distinct_id(),
                    $set: {
                        [LoggingPersonProperties.STUDENT_DATA_DEPENDENCY]: dependencyStatus,
                        [LoggingPersonProperties.STUDENT_DATA_HOUSEHOLD_SIZE]: numberInHousehold,
                        [LoggingPersonProperties.STUDENT_DATA_HOUSEHOLD_STUDENTS_SIZE]:
                            numberOfStudentsInHousehold,
                        [LoggingPersonProperties.STUDENT_DATA_AGE]: age,
                        [LoggingActivityProperties.EFC_CALCULATED]: efcCalculated,
                        [LoggingActivityProperties.SAI_CALCULATED]: saiCalculated,
                        [LoggingActivityProperties.PELL_TYPE]:
                            student.onboarding.financial.pellType,
                        [LoggingPersonProperties.STUDENT_FINISHED_ONBOARDING]: true,
                        [LoggingPersonProperties.STUDENT_DATA_STATE]:
                            student.onboarding.personal.state ?? "International",
                    },
                },
            ];

            await axios.put(`${API_ROOT_URL}/mixpanel/profile-set`, mixpanelData);
        }
    }

    public static onboardingStartPage() {
        this.timeEvent(LoggingEvents.COMPLETED_ONBOARDING_STEP);
    }

    public static async onboardingEndPage(
        subStep: AllSubStepIds,
        action: OnboardingButtonAction,
        actions: { key: string & OnboardingStateDataIds; value: any }[],
        student: Student
    ) {
        const studentUtil: StudentUtil = new StudentUtil(student);
        student = cloneDeep(student);

        const data = this.extractChangedFields(actions);
        data[LoggingActivityProperties.ONBOARDING_CHANGE_LOG] = actions;
        data[LoggingActivityProperties.ONBOARDING_PAGE] = subStep;
        data[LoggingActivityProperties.NEXT_ACTION] = action;
        this.track(LoggingEvents.COMPLETED_ONBOARDING_STEP, data);

        const [dependencyStatus, numberInHousehold, numberOfStudentsInHousehold, age] = [
            studentUtil.getDependencyStatus(),
            studentUtil.getNumberInHousehold(),
            studentUtil.getNumberOfStudentsInHousehold(),
            studentUtil.getStudentsAge(),
        ];

        const mixpanelDataToSet: { [x: string]: any } = {};
        for (const { key } of actions) {
            if (
                [
                    OnboardingStateDataIds.BIRTHDAY,
                    OnboardingStateDataIds.STATE,
                    OnboardingStateDataIds.CITIZENSHIP_STATUS,
                ].includes(key)
            ) {
                // track state and citizenship status for case where user is born at
                // 01/01/2000, in which case they wouldn't change the birthdate field
                // but would necessarily change one of the other fields
                mixpanelDataToSet[LoggingPersonProperties.STUDENT_DATA_AGE] = age;
            }
            if (
                [
                    OnboardingStateDataIds.BIRTHDAY,
                    OnboardingStateDataIds.MARITAL_STATUS,
                    OnboardingStateDataIds.HAS_MILITARY_STATUS,
                    OnboardingStateDataIds.HAS_DEPENDENT_CHILDREN,
                ].includes(key)
            ) {
                mixpanelDataToSet[LoggingPersonProperties.STUDENT_DATA_DEPENDENCY] =
                    dependencyStatus;
            }
            if (
                [
                    OnboardingStateDataIds.SIBLINGS,
                    OnboardingStateDataIds.SIBLINGS_COUNT,
                    OnboardingStateDataIds.MARITAL_STATUS,
                    OnboardingStateDataIds.IS_SPOUSE_ENROLLED,
                    OnboardingStateDataIds.DEPENDENT_CHILDREN,
                    OnboardingStateDataIds.DEPENDENT_CHILDREN_ENROLLED,
                ].includes(key)
            ) {
                mixpanelDataToSet[LoggingPersonProperties.STUDENT_DATA_HOUSEHOLD_STUDENTS_SIZE] =
                    numberOfStudentsInHousehold;
                mixpanelDataToSet[LoggingPersonProperties.STUDENT_DATA_HOUSEHOLD_SIZE] =
                    numberInHousehold;
            }
        }

        if (Object.keys(mixpanelDataToSet).length) {
            if (!student.email) {
                // if user is anonymous
                const mixpanelData = [
                    {
                        $token: process.env.REACT_APP_MIXPANEL_TOKEN,
                        $distinct_id: this.mixpanel().get_distinct_id(),
                        $set: mixpanelDataToSet,
                    },
                ];

                axios.put(`${API_ROOT_URL}/mixpanel/profile-set`, mixpanelData);
            } else {
                // if user is identified
                if (mixpanelDataToSet[LoggingPersonProperties.STUDENT_DATA_DEPENDENCY]) {
                    this.mixpanel().people.set(
                        LoggingPersonProperties.STUDENT_DATA_DEPENDENCY,
                        dependencyStatus
                    );
                }
                if (mixpanelDataToSet[LoggingPersonProperties.STUDENT_DATA_HOUSEHOLD_SIZE]) {
                    this.mixpanel().people.set(
                        LoggingPersonProperties.STUDENT_DATA_HOUSEHOLD_SIZE,
                        numberInHousehold
                    );
                }
                if (
                    mixpanelDataToSet[LoggingPersonProperties.STUDENT_DATA_HOUSEHOLD_STUDENTS_SIZE]
                ) {
                    this.mixpanel().people.set(
                        LoggingPersonProperties.STUDENT_DATA_HOUSEHOLD_STUDENTS_SIZE,
                        numberOfStudentsInHousehold
                    );
                }
                if (mixpanelDataToSet[LoggingPersonProperties.STUDENT_DATA_AGE]) {
                    this.mixpanel().people.set(LoggingPersonProperties.STUDENT_DATA_AGE, age);
                }
            }
        }
    }

    private static extractChangedFields(actions: { key: string; value: any }[]) {
        const changeCount = {};
        for (const action of actions) {
            if (action.value instanceof Date && !isNaN(Number(action.value))) {
                action.value = this.convertDateToString(action.value);
            }
            const targetKey = `countChanges:${action.key}`;
            changeCount[targetKey] = (changeCount[targetKey] ?? 0) + 1;
        }
        return changeCount;
    }

    public static loadedNetPriceCalculator(props: { schoolId: string; previousPage?: string }) {
        this.track(LoggingEvents.LOADED_CALCULATOR, props);
        this.timeEvent(LoggingEvents.CHANGE_NET_PRICE_PERIOD);
        this.timeEvent(LoggingEvents.COMPLETED_CALCULATOR);
    }

    public static viewAnswerGuideItem(answerGuideItem: AnswerGuideItem) {
        const data = {};
        data[LoggingActivityProperties.ANSWER_GUIDE_TITLE] = answerGuideItem.title;
        this.track(LoggingEvents.ANSWER_GUIDE_VIEW, data);
    }

    public static disclaimerAction(disclaimerAction: DisclaimerAction) {
        const data = {};
        data[LoggingActivityProperties.DISCLAIMER_ACTION] = disclaimerAction;
        this.track(LoggingEvents.DISCLAIMER_ACTION, data);
    }

    public static changeNetPricePeriod(
        previousNetPricePeriod: NetPricePeriod,
        newNetPricePeriod: NetPricePeriod
    ) {
        const data = {};
        data[LoggingActivityProperties.CURRENT_NET_PRICE_PERIOD] = newNetPricePeriod;
        data[LoggingActivityProperties.PREVIOUS_NET_PRICE_PERIOD] = previousNetPricePeriod;
        this.track(LoggingEvents.CHANGE_NET_PRICE_PERIOD, data);
        this.timeEvent(LoggingEvents.CHANGE_NET_PRICE_PERIOD);
    }

    public static finishCalculator(estimate: NetPriceEstimate) {
        const data = {
            [LoggingActivityProperties.ESTIMATE_COA]: estimate.costOfAttendance.total,
            [LoggingActivityProperties.ESTIMATE_PELL]:
                estimate.scholarshipAid.needBasedAid.federalPellGrant,
            [LoggingActivityProperties.ESTIMATE_STATE_GRANT]:
                estimate.scholarshipAid.needBasedAid.stateGrant,
            [LoggingActivityProperties.ESTIMATE_INSTITUTIONAL_GRANT]:
                estimate.scholarshipAid.needBasedAid.institutionalGrant,
            [LoggingActivityProperties.ESTIMATE_MERIT_AID]: estimate.scholarshipAid.meritAid,
            [LoggingActivityProperties.ESTIMATE_MILITARY_BENEFITS]:
                estimate.scholarshipAid.militaryAid.total,
            [LoggingActivityProperties.ESTIMATE_NET_PRICE]: estimate.total,
        };
        this.track(LoggingEvents.COMPLETED_CALCULATOR, data);
    }

    public static openRefineContainer() {
        this.timeEvent(LoggingEvents.CALCULATOR_CONTAINER);
    }

    public static closeRefineContainer(
        container: string,
        actions: { key: string; value: any }[],
        coa: number,
        netPricePeriod: NetPricePeriod
    ) {
        const data = this.extractChangedFields(actions);
        data[LoggingActivityProperties.CALCULATOR_CONTAINER] = container;
        data[LoggingActivityProperties.CALCULATOR_CHANGE_LOG] = actions;
        data[LoggingActivityProperties.CURRENT_NET_PRICE_PERIOD] = netPricePeriod;
        data[LoggingActivityProperties.CALCULATOR_COST_OF_ATTENDANCE] = coa;
        this.track(LoggingEvents.CALCULATOR_CONTAINER, data);
    }

    public static mobileInteraction(mobileInteraction: MobileInteraction) {
        const data = {};
        data[LoggingActivityProperties.MOBILE_INTERACTION_ACTION] = mobileInteraction;
        this.track(LoggingEvents.MOBILE_ACTION, data);
    }

    public static waysToCoverClick(type: WaysToCoverType, name: string) {
        const data = {};
        data[LoggingActivityProperties.WAYS_TO_COVER_TYPE] = type;
        data[LoggingActivityProperties.WAYS_TO_COVER_NAME] = name;
        this.track(LoggingEvents.WAYS_TO_COVER, data);
    }

    public static vaBenefitsResources(name: string) {
        const data = {};
        data[LoggingActivityProperties.VA_BENEFITS_NAME] = name;
        this.track(LoggingEvents.VA_BENEFITS, data);
    }

    public static nextStepsToggle(checked: boolean, name: string) {
        const data = {};
        data[LoggingActivityProperties.NEXT_STEPS_ACTION] = checked ? "Checked" : "Unchecked";
        data[LoggingActivityProperties.NEXT_STEPS_NAME] = name;
        this.track(LoggingEvents.NEXT_STEPS, data);
    }

    public static changedLanguage(currentLanguage: Language) {
        const data = {};
        data[LoggingActivityProperties.CURRENT_LANGUAGE] = currentLanguage;
        this.track(LoggingEvents.CHANGED_LANGUAGE, data);
    }

    public static loadedMeadowAdmin() {
        this.track(LoggingEvents.LOADED_MEADOW_ADMIN);
    }

    public static createdSchool(data: Partial<School>) {
        this.track(LoggingEvents.CREATED_SCHOOL, data);
    }

    public static openedSchoolDetails(data: Partial<School>) {
        this.track(LoggingEvents.OPENED_SCHOOL_DETAILS, data);
    }

    public static registerProduct(product: MeadowProduct) {
        this.mixpanel().register({ [LoggingActivityProperties.MEADOW_PRODUCT]: product });
    }

    public static registerLanguage(language: Language) {
        this.mixpanel().register({ [LoggingActivityProperties.LANGUAGE]: language });
    }

    public static dtcSchoolView(id: number, name: string) {
        const data = {
            [LoggingActivityProperties.DTC_SCHOOL_ID]: id,
            [LoggingActivityProperties.DTC_SCHOOL_NAME]: name,
        };

        this.track(LoggingEvents.DTC_SCHOOL_VIEW, data);
        const mixpanelUnionData = [
            {
                $token: process.env.REACT_APP_MIXPANEL_TOKEN,
                $distinct_id: this.mixpanel().get_distinct_id(),
                $union: {
                    [LoggingPersonProperties.DTC_SCHOOLS_VIEWED]: [id],
                },
            },
        ];
        axios.put(`${API_ROOT_URL}/mixpanel/profile-union`, mixpanelUnionData);
        this.mixpanel().people.union(LoggingPersonProperties.DTC_SCHOOLS_VIEWED, id);
    }

    public static dtcSchoolSearch(results: Partial<DTCSchool>[], search?: string) {
        if (!search) {
            return;
        }

        const data = results.map((school) => {
            return {
                [LoggingActivityProperties.DTC_SCHOOL_ID]: school.unitid,
                [LoggingActivityProperties.DTC_SCHOOL_NAME]: school.details?.name,
            };
        });

        data[LoggingActivityProperties.DTC_SEARCH_STRING] = search;

        this.track(LoggingEvents.DTC_SCHOOL_SEARCH, data);
    }

    public static dtcSaveSchoolToList(
        schoolId: number,
        schoolName: string,
        page: "Browse" | "MyPlan" | "NPCDoorway",
        savedSchools: any
    ) {
        const data = {
            [LoggingActivityProperties.DTC_SCHOOL_ID]: schoolId,
            [LoggingActivityProperties.DTC_SCHOOL_NAME]: schoolName,
            [LoggingActivityProperties.DTC_MY_SCHOOL_ACTION_PAGE]: page,
        };
        this.track(LoggingEvents.DTC_SAVE_TO_MY_SCHOOL, data);

        this.updatePeopleMySchool(savedSchools);
    }

    public static dtcRemoveSchoolFromList(schoolId: number, schoolName: string, savedSchools: any) {
        const data = {
            [LoggingActivityProperties.DTC_SCHOOL_ID]: schoolId,
            [LoggingActivityProperties.DTC_SCHOOL_NAME]: schoolName,
        };
        this.track(LoggingEvents.DTC_REMOVE_FROM_MY_SCHOOL, data);
        this.updatePeopleMySchool(savedSchools);
    }

    public static shareToSchool() {
        this.track(LoggingEvents.SHARE_TO_SCHOOL);
    }

    private static async updatePeopleMySchool(savedSchools: any) {
        const mixpanelData = [
            {
                $token: process.env.REACT_APP_MIXPANEL_TOKEN,
                $distinct_id: this.mixpanel().get_distinct_id(),
                $set: {
                    [LoggingPersonProperties.DTC_MY_SCHOOL_LIST]: savedSchools,
                },
            },
        ];
        await axios.put(`${API_ROOT_URL}/mixpanel/profile-set`, mixpanelData);
        this.mixpanel().people.set(LoggingPersonProperties.DTC_MY_SCHOOL_LIST, savedSchools);
    }

    private static convertDateToString(date: Date): string {
        return date.toISOString().split("T")[0];
    }
}
