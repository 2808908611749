import { Theme } from "@mui/material";
import { BLACK, DS_V2_GREY_200, TYPOGRAPHY_0, TYPOGRAPHY_100 } from "../../theme/colors";

const getTypographyElement = (element) => {
    return { ...element, fontFamily: "'Tisa Sans Pro', sans-serif" };
};

const theme = (outerTheme: Theme): Theme => {
    return {
        ...outerTheme,
        typography: {
            ...outerTheme.typography,
            fontFamily: "'Tisa Sans Pro', sans-serif",
            h1: getTypographyElement(outerTheme.typography.h1),
            h2: getTypographyElement(outerTheme.typography.h2),
            h3: getTypographyElement(outerTheme.typography.h3),
            h4: getTypographyElement(outerTheme.typography.h4),
            h5: getTypographyElement(outerTheme.typography.h5),
            h6: getTypographyElement(outerTheme.typography.h6),
            body1: getTypographyElement(outerTheme.typography.body1),
            body2: getTypographyElement(outerTheme.typography.body2),
            subtitle1: getTypographyElement(outerTheme.typography.subtitle1),
            button: getTypographyElement(outerTheme.typography.button),
            caption: getTypographyElement(outerTheme.typography.caption),
        },
        components: {
            ...outerTheme.components,
            MuiButton: {
                ...outerTheme.components?.MuiButton,
                variants: [
                    ...outerTheme.components!.MuiButton!.variants!,
                    {
                        props: { variant: "primary" },
                        style: {
                            background: BLACK,
                            color: TYPOGRAPHY_0,
                            fontSize: "1.125rem",
                            padding: "12px 20px",
                            borderRadius: "84px",
                            "&:hover": {
                                background: BLACK,
                                opacity: 0.8,
                            },
                            "&:disabled": {
                                background: BLACK,
                                color: TYPOGRAPHY_0,
                                opacity: 0.3,
                            },
                        },
                    },
                    {
                        props: { variant: "primaryAlternative" },
                        style: {
                            background: TYPOGRAPHY_0,
                            color: TYPOGRAPHY_100,
                            fontSize: "1.125rem",
                            padding: "16px 24px",
                            borderRadius: "84px",
                            "&:hover": {
                                background: TYPOGRAPHY_0,
                                opacity: 0.8,
                            },
                            "&:disabled": {
                                background: "transparent",
                                color: TYPOGRAPHY_0,
                                opacity: 0.3,
                            },
                        },
                    },

                    {
                        props: { variant: "secondary" },
                        style: {
                            background: "transparent",
                            color: BLACK,
                            border: `2px solid ${DS_V2_GREY_200}`,
                            padding: "0px 8px",
                            borderRadius: "48px",
                            fontWeight: 500,
                            fontSize: "1rem",
                            "&:disabled": {
                                border: `2px solid ${DS_V2_GREY_200}50`,
                                opacity: 0.5,
                            },
                        },
                    },
                ],
            },
        },
    };
};

export default theme;
