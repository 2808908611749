import { MaritalStatus } from "@meadow-fi/price-libraries/enums/onboarding";
import { Onboarding, OnboardingFamily } from "@meadow-fi/price-libraries/interfaces/onboarding";
import { OnboardingReducerStateData } from "../contexts/onboarding";
import { BooleanOptionValues } from "../enums/form";
import { currencyToNumber } from "./format";

export const mapOnboardingReducerToStudentOnboarding = (
    stateData: OnboardingReducerStateData
): Onboarding => {
    const onboarding: Onboarding = {
        personal: {
            ...stateData.personal,
            maritalStatus: stateData.personal.maritalStatus
                ? MaritalStatus.MARRIED
                : MaritalStatus.NEVER_MARRIED,
            isSpouseEnrolled:
                stateData.personal.isSpouseEnrolled === BooleanOptionValues.YES ? true : false,
        },
        financial: {
            ...stateData.financial,
            knownEFC: {
                efc: Number(stateData.financial.knownEFC?.efc),
                amountAffordPerYear: currencyToNumber(
                    stateData.financial.knownEFC?.amountAffordPerYear
                ),
            },
            manualEFC: {
                parents: {
                    incomeParent1: Number(stateData.financial.manualEFC?.parents?.incomeParent1),
                    incomeParent2: Number(stateData.financial.manualEFC?.parents?.incomeParent2),
                    amountAffordPerYear: Number(
                        stateData.financial.manualEFC?.parents?.amountAffordPerYear
                    ),
                    savingsBalance: currencyToNumber(
                        stateData.financial.manualEFC?.parents?.savingsBalance
                    ),
                    investmentProperties: currencyToNumber(
                        stateData.financial.manualEFC?.parents?.investmentProperties
                    ),
                    businessValue: currencyToNumber(
                        stateData.financial.manualEFC?.parents?.businessValue
                    ),
                },
                student: {
                    annualIncome: Number(stateData.financial.manualEFC?.student.annualIncome),
                    spouseAnnualIncome: Number(
                        stateData.financial.manualEFC?.student.spouseAnnualIncome
                    ),
                    savingsBalance: currencyToNumber(
                        stateData.financial.manualEFC?.student.savingsBalance
                    ),
                    status: stateData.financial.manualEFC?.student.status,
                },
            },
        },
    };

    if (stateData.family?.parentsMaritalStatus || stateData.family?.siblings?.length) {
        if (!onboarding.family) {
            onboarding.family = {} as OnboardingFamily;
        }
        if (stateData.family?.parentsMaritalStatus) {
            onboarding.family.parentsMaritalStatus = stateData.family.parentsMaritalStatus;
        }
        if (stateData.family?.parentsResidency) {
            onboarding.family.parentsResidency = stateData.family.parentsResidency;
        }
        if (stateData.family?.siblings.length) {
            onboarding.family.siblings = stateData.family.siblings;
        }
        if (stateData.family?.householdMembers) {
            onboarding.family.householdMembers = stateData.family.householdMembers;
        }
        if (stateData.family?.householdMembersEnrolled) {
            onboarding.family.householdMembersEnrolled = stateData.family.householdMembersEnrolled;
        }
    }

    return onboarding;
};
