import {
    DegreeType,
    MilitaryStatus,
    StandardizedTest,
    StudentPlacement,
    TextbookAcquisition,
    TransportationType,
} from "@meadow-fi/price-libraries/enums/net-price";
import {
    MaritalStatus,
    ParentsResidency,
    StudentStatus,
} from "@meadow-fi/price-libraries/enums/onboarding";
import { Siblings } from "@meadow-fi/price-libraries/interfaces/onboarding";
import { Student } from "@meadow-fi/price-libraries/interfaces/student";
import { OnboardingReducerStateData } from "../contexts/onboarding";
import { BooleanOptionValues } from "../enums/form";
import { SelectOption } from "../types/form";
import { formatCurrency } from "./format";

export const mapApiStudentToOnboardingStateData = (
    student: Student
): OnboardingReducerStateData => {
    const { personal, family, financial } = student.onboarding;

    const onboarding: OnboardingReducerStateData = {
        personal: {
            ...personal,
            birthday: new Date(personal.birthday),
            maritalStatus: personal.maritalStatus === MaritalStatus.MARRIED ? true : false,
            isSpouseEnrolled:
                personal.isSpouseEnrolled === true
                    ? BooleanOptionValues.YES
                    : BooleanOptionValues.NO,
        },
        family: {
            ...family,
            parentsMaritalStatus: family?.parentsMaritalStatus
                ? family.parentsMaritalStatus
                : ("" as MaritalStatus),
            parentsResidency: family?.parentsResidency
                ? family.parentsResidency
                : ("" as ParentsResidency),
            siblings: family?.siblings?.length
                ? (family.siblings.map((s) => ({ ...s, age: s.age.toString() })) as unknown as [
                      Siblings
                  ])
                : ([] as unknown as [Siblings]),
            householdMembers: family?.householdMembers ? family.householdMembers : 3,
            householdMembersEnrolled: family?.householdMembersEnrolled
                ? family.householdMembersEnrolled
                : 1,
        },
        financial: {
            ...financial,
            knownEFC: {
                efc: financial.knownEFC?.efc?.toString() ?? "",
                amountAffordPerYear: financial.knownEFC?.amountAffordPerYear
                    ? formatCurrency(financial.knownEFC.amountAffordPerYear, true)
                    : "",
            },
            manualEFC: {
                parents: {
                    incomeParent1: financial.manualEFC?.parents?.incomeParent1.toString() || "",
                    incomeParent2: financial.manualEFC?.parents?.incomeParent2.toString() || "",
                    amountAffordPerYear:
                        financial.manualEFC?.parents?.amountAffordPerYear.toString() || "",
                    savingsBalance: financial.manualEFC?.parents?.savingsBalance
                        ? formatCurrency(financial.manualEFC.parents.savingsBalance, true)
                        : "",
                    investmentProperties: financial.manualEFC?.parents?.investmentProperties
                        ? formatCurrency(financial.manualEFC.parents.investmentProperties, true)
                        : "",
                    businessValue: financial.manualEFC?.parents?.businessValue
                        ? formatCurrency(financial.manualEFC.parents.businessValue, true)
                        : "",
                },
                student: {
                    annualIncome: Number(financial.manualEFC?.student.annualIncome) || undefined,
                    spouseAnnualIncome:
                        Number(financial.manualEFC?.student.spouseAnnualIncome) || undefined,
                    savingsBalance: financial.manualEFC?.student.savingsBalance
                        ? formatCurrency(financial.manualEFC.student.savingsBalance, true)
                        : "",
                    status: financial.manualEFC?.student.status || ("" as StudentStatus),
                },
            },
            // these are all optional and could be undefined in Price Libraries
            maxPell: financial.maxPell,
            minPell: financial.minPell,
            saiPell: financial.saiPell,
            pellType: financial.pellType,
        },
        hasDependentChildren: personal.dependentChildren > 0,
        hasParentsBusiness: Number(financial.manualEFC?.parents?.businessValue) > 0,
        hasParentsProperties: Number(financial.manualEFC?.parents?.investmentProperties) > 0,
        hasParentsSavings: Number(financial.manualEFC?.parents?.savingsBalance) > 0,
        hasStudentSavings: Number(financial.manualEFC?.student?.savingsBalance) > 0,
        siblingsCount: family?.siblings?.length ? Number(family.siblings.length) : 0,
        siblingsCountEnrolled: family?.siblings?.length
            ? family.siblings.filter((sibling) => sibling.isEnrolledInCollege).length
            : 0,
        efc: financial.knownEFC?.efc || 0,
        hasFinishedOnboarding: student.hasFinishedOnboarding,
        householdMembers: family?.householdMembers ? family.householdMembers : 3,
        householdMembersEnrolled: family?.householdMembersEnrolled
            ? family.householdMembersEnrolled
            : 1,
    };

    return onboarding;
};

export const mapCalculatorFieldOptionsToArray = (fieldKey: string): string[] => {
    const enumMatch = {
        degreeType: DegreeType,
        housing: StudentPlacement,
        meals: StudentPlacement,
        combined: StudentPlacement,
        textbookAcquisition: TextbookAcquisition,
        transportation: TransportationType,
        serviceStatus: MilitaryStatus,
        standardizedTest: StandardizedTest,
    }[fieldKey];

    if (!enumMatch) {
        return [];
    }

    return Object.keys(enumMatch);
};

export const mapEnumToSelect = (e): SelectOption[] =>
    Object.keys(e).map((str: string) => ({ label: str, value: str }));

export const mapDTCSchoolSizeNumberToLabel = (size?: number): string => {
    if (!size) {
        return "";
    }

    if (size > 4) {
        return "Large";
    }
    if (size > 2) {
        return "Medium";
    }
    return "Small";
};
