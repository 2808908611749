import { createContext, Dispatch } from "react";

export enum AppReducerActionTypes {
    SET_IS_UPDATING = "SET_IS_UPDATING",
    SET_HAS_UPLOAD_ERROR = "SET_HAS_UPLOAD_ERROR",
    SET_POPUP_WINDOW = "SET_POPUP_WINDOW",
    SET_SHOW_BACKDROP = "SET_SHOW_BACKDROP",
}

export type AppReducerAction = {
    type: AppReducerActionTypes;
    payload: boolean | Window | null;
};

export type AppReducerState = {
    isUploading: boolean;
    hasUploadError: boolean;
    popupWindow: Window | null;
    showBackdrop: boolean;
};

export const appReducerInitialState: AppReducerState = {
    isUploading: false,
    hasUploadError: false,
    popupWindow: null,
    showBackdrop: false,
};

export const AppContext = createContext<{
    state: AppReducerState;
    dispatch: Dispatch<AppReducerAction>;
}>({
    state: appReducerInitialState,
    dispatch: () => null,
});

export const appReducer = (state: AppReducerState, action: AppReducerAction) => {
    switch (action.type) {
        case AppReducerActionTypes.SET_IS_UPDATING: {
            return {
                ...state,
                isUploading: action.payload as boolean,
            };
        }
        case AppReducerActionTypes.SET_HAS_UPLOAD_ERROR: {
            return {
                ...state,
                hasUploadError: action.payload as boolean,
            };
        }
        case AppReducerActionTypes.SET_POPUP_WINDOW: {
            return {
                ...state,
                popupWindow: action.payload as Window | null,
            };
        }
        case AppReducerActionTypes.SET_SHOW_BACKDROP: {
            return {
                ...state,
                showBackdrop: action.payload as boolean,
            };
        }

        default:
            return state;
    }
};
