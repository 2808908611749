import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { Amplify } from "aws-amplify";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import I18NextHttpBackend from "i18next-http-backend";
import React from "react";
import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";
import App from "./App";
import awsExports from "./aws-exports";
import { MEADOW_PROD_FRONTEND_URL } from "./constants/general";
import { Language } from "./enums/all";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import { Logging } from "./utils/Logging";

const sentrySampleRate = parseInt(process.env.REACT_APP_SENTRY_SAMPLE_RATE ?? "1", 10);
const sentryReplaySampleRate = parseInt(
    process.env.REACT_APP_SENTRY_REPLAY_SESSION_RATE ?? "0.5",
    10
);

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new BrowserTracing(),
        new Sentry.Replay({
            maskAllInputs: true,
            blockAllMedia: true,
        }),
    ],
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
    ],
    tracesSampleRate: sentrySampleRate,
    debug: process.env.NODE_ENV == "development" && !!process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_COMMIT_SHA,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? "development",
    replaysSessionSampleRate: sentryReplaySampleRate,
    replaysOnErrorSampleRate: 1.0,
});

Amplify.configure(awsExports);

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(I18NextHttpBackend)
    .init(
        {
            debug: process.env.NODE_ENV == "development",
            supportedLngs: ["es", "en"],
            fallbackLng: "en",
            react: {
                transWrapTextNodes: "span",
            },
            detection: {
                order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
                caches: ["cookie"],
            },
            backend: {
                loadPath: "/assets/locales/{{lng}}/{{ns}}.json",
            },
        },
        () => {
            const language = i18n.language as Language;
            Logging.registerLanguage(language);
        }
    );

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
            clientId={process.env.REACT_APP_AUTH0_STUDENT_CLIENT_ID as string}
            audience={MEADOW_PROD_FRONTEND_URL}
            scope="openid profile email"
        >
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
