import { DEFAULT_DTC_NET_PRICE } from "@meadow-fi/price-libraries/defaults/dtc-net-price";
import { DTCNetPriceEstimate } from "@meadow-fi/price-libraries/interfaces/dtc-net-price";
import { DTCNetPriceInput } from "@meadow-fi/price-libraries/interfaces/dtc-net-price-input";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import { createContext, Dispatch } from "react";
import { NavigateFunction } from "react-router-dom";
import { EMPTY_DTC_NET_PRICE_ESTIMATE } from "../constants/empty";
import { ActionTypes } from "../enums/all";
import { NetPriceCalculatorDataIds } from "../enums/netPriceCalculator";
import { NpcLocationData, NpcLocationGetDataUpdateKeys } from "../hooks/useNpcLocation";

export enum DTCNetPriceCalculatorReducerActionTypes {
    SET_ESTIMATE = "SET_ESTIMATE",
    SET_LOCATION_STATE = "SET_LOCATION_STATE",
    RESET_DIFF = "RESET_DIFF",
    SET_IS_LOADING = "SET_IS_LOADING",
    CLEAR_DATA = "CLEAR_DATA",
    CLEAR_ALL = "CLEAR_ALL",
    SET_SELECTED_TAB = "SET_SELECTED_TAB",
}

export type DTCNetPriceCalculatorReducerAction =
    | {
          type: DTCNetPriceCalculatorReducerActionTypes.SET_ESTIMATE;
          payload: DTCNetPriceEstimate;
      }
    | {
          type: ActionTypes.SET_DATA;
          payload: DTCNetPriceInput;
      }
    | {
          type: ActionTypes.UPDATE_DATA;
          payload: Partial<DTCNetPriceEstimate> | { [key in NetPriceCalculatorDataIds]?: any };
          navigate?: NavigateFunction;
          getNpcLocationData?: (update?: {
              [key in NpcLocationGetDataUpdateKeys]?: any;
          }) => NpcLocationData;
      }
    | {
          type: DTCNetPriceCalculatorReducerActionTypes.SET_IS_LOADING;
          payload: boolean;
      }
    | {
          type: DTCNetPriceCalculatorReducerActionTypes.RESET_DIFF;
      }
    | {
          type: DTCNetPriceCalculatorReducerActionTypes.CLEAR_ALL;
      }
    | {
          type: DTCNetPriceCalculatorReducerActionTypes.CLEAR_DATA;
      }
    | {
          type: DTCNetPriceCalculatorReducerActionTypes.SET_SELECTED_TAB;
          payload: string;
      };

export type DTCNetPriceCalculatorReducerState = {
    data: DTCNetPriceInput;
    estimate: DTCNetPriceEstimate;
    dataDiff: { key: string; value: any }[];
    isLoading: boolean;
    selectedTab: string;
};

export const DTCNetPriceCalculatorReducerInitialState: DTCNetPriceCalculatorReducerState = {
    data: cloneDeep(DEFAULT_DTC_NET_PRICE),
    estimate: EMPTY_DTC_NET_PRICE_ESTIMATE,
    dataDiff: [],
    isLoading: false,
    selectedTab: "0",
};

export const DTCNetPriceCalculatorContext = createContext<{
    state: DTCNetPriceCalculatorReducerState;
    dispatch: Dispatch<DTCNetPriceCalculatorReducerAction>;
}>({
    state: DTCNetPriceCalculatorReducerInitialState,
    dispatch: () => null,
});

export const DTCNetPriceCalculatorReducer = (
    state: DTCNetPriceCalculatorReducerState,
    action: DTCNetPriceCalculatorReducerAction
): DTCNetPriceCalculatorReducerState => {
    switch (action.type) {
        case DTCNetPriceCalculatorReducerActionTypes.RESET_DIFF: {
            return {
                ...state,
                dataDiff: [],
            };
        }
        case DTCNetPriceCalculatorReducerActionTypes.SET_ESTIMATE: {
            return {
                ...state,
                estimate: action.payload,
            };
        }
        case ActionTypes.SET_DATA: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case ActionTypes.UPDATE_DATA: {
            const key = Object.keys(action.payload)[0];
            const value = cloneDeep(Object.values(action.payload)[0]);

            const dataDiff = cloneDeep(state.dataDiff || []);
            dataDiff.push({ key, value });

            const updatedData: DTCNetPriceInput = cloneDeep(set(state.data, key, value));

            return {
                ...state,
                data: updatedData,
                dataDiff,
            };
        }
        case DTCNetPriceCalculatorReducerActionTypes.CLEAR_ALL: {
            return { ...state, data: DTCNetPriceCalculatorReducerInitialState.data };
        }
        case DTCNetPriceCalculatorReducerActionTypes.CLEAR_DATA: {
            return { ...state, data: DTCNetPriceCalculatorReducerInitialState.data };
        }
        case DTCNetPriceCalculatorReducerActionTypes.SET_IS_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }
        case DTCNetPriceCalculatorReducerActionTypes.SET_SELECTED_TAB: {
            return {
                ...state,
                selectedTab: action.payload,
            };
        }

        default:
            return state;
    }
};
