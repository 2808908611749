// all colors used in the app should be defined here

export const PRIMARY_120 = "#00A5A3";
export const PRIMARY_100 = "#04D2CF";
export const PRIMARY_90 = "#04DDC8";
export const PRIMARY_80 = "#04E4C2";

export const SECONDARY_110 = "#02335E";
export const SECONDARY_100 = "#03345F";
export const SECONDARY_90 = "#033963";
export const SECONDARY_70 = "#045876";
export const SECONDARY_60 = "#047387";
export const SECONDARY_50 = "#02C0DA";
export const SECONDARY_40 = "#03C1DB";
export const SECONDARY_30 = "#04B1E3";
export const SECONDARY_20 = "#07AEE0";
export const SECONDARY_10 = "rgba(4, 177, 227, .2)";
export const SECONDARY_ACC = "#007FAE";

export const TYPOGRAPHY_100 = "#000000";
export const TYPOGRAPHY_80 = "#274551";
export const TYPOGRAPHY_60 = "#707070";
export const TYPOGRAPHY_50 = "#808080";
export const TYPOGRAPHY_20 = "#C8C8CC";
export const TYPOGRAPHY_0 = "#FFFFFF";

export const GREEN_1 = "#0A6B45";
export const TYPOGRAPHY_LINK = "#25C0DA";
export const TYPOGRAPHY_DARK_BLUE = "#1B3E4B";
export const TYPOGRAPHY_BLUE = "#004890";
export const ERROR_100 = "#E33A04";

export const WHITE = "#fff";

export const GREY_50 = "#F7F9FA";
export const GREY_30 = "#E3E3E3";
export const GREY_20 = "#F0F5F9";
export const GREY_200 = "#EAECF0";
export const GREY_500 = "#667085";
export const GREY_900 = "#101828";

export const GREY_ARSENIC = "#5B616E";
export const GREY_ALPHA_20 = "rgba(0, 0, 0, 0.2)";
export const GREY_ALPHA_10 = "rgba(0, 0, 0, 0.1)";

export const BLACK = "#000";
export const BLACK_500 = "#5B616E";
export const DS_V2_GREY_100 = "#E6EBF3";
export const DS_V2_GREY_200 = "#D5DCE6";
export const DS_V2_GREY_150 = "#E6E6E6";

export const BACKGROUND_DEFAULT = "#FFF";
export const BACKGROUND_LIGHT_BLUE = "#FAFDFF";
export const BACKGROUND_LIGHT_BLUE_1 = "#E8F1FF";
export const BACKGROUND_BLUE = "#F0F5F9";
export const BACKGROUND_LIGHT_GREY = "#F2F4F7";

export const TOOLTIP_BACKGROUND_LIGHT = "#03C1DB";
export const TOOLTIP_BACKGROUND_DARK = "#043F66";

export const GREEN_100 = "#0C0";
export const RED_100 = "#C00";
export const ESTIMATE_RED = "#F55D60";
export const ESTIMATE_GREEN = "#0F935B";

export const DARK_BLUE = "#054067";

export const COST_COLOR = "#FE8D93";

export const BLUE_SEARCH_BACKGROUND = "#F1F9FE";
export const BLUE_GRADIENT_BG = "linear-gradient(211.94deg, #043A64 33.48%, #045876 80.8%)";
export const BLUE_GRADIENT_BG_2 = "linear-gradient(103.76deg, #06456B 10.94%, #055E79 106.76%)";
export const HIGHLIGHT_GRADIENT = "linear-gradient(91.36deg, #03C1DB 11.8%, #04DDC8 101.48%);";
export const SEARCH_BACKGROUND_SHADOW = "rgba(5, 64, 103, 0.2)";

export const GREY_BORDER = "#EAECF0";
export const BLUE_BORDER = "#275190";

export const BLUEBERRY_500 = "#425FD7";
export const DISABLED_BUTTON = "#939BAC";
