import { SchoolBranding } from "@meadow-fi/price-libraries/interfaces/school";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MEADOW_BLUE } from "../constants/general";
import { SchoolContext } from "../contexts/school";
import { MeadowProduct } from "../enums/all";
import { RouteNames } from "../enums/routes";
import { BREAKPOINT_MD } from "../theme";
import useWindowWidth from "./useWindowWidth";

const useSystem = () => {
    const { pathname } = useLocation();
    const { current: school } = useContext(SchoolContext).state;
    const DEFAULT_LOGO_WIDTH = { npc: 120, dtcDesktop: 200, dtcMobile: 78 };
    const MOBILE_BREAKPOINT = 640;

    const windowWidth = useWindowWidth();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isTablet, setIsTablet] = useState<boolean>(false);

    useEffect(() => {
        if (!isMobile && windowWidth <= MOBILE_BREAKPOINT) {
            setIsMobile(true);
        } else if (isMobile && windowWidth > MOBILE_BREAKPOINT) {
            setIsMobile(false);
        }
        if (!isTablet && windowWidth > MOBILE_BREAKPOINT && windowWidth <= BREAKPOINT_MD) {
            setIsTablet(true);
        } else if (isTablet && (windowWidth > BREAKPOINT_MD || windowWidth <= MOBILE_BREAKPOINT)) {
            setIsTablet(false);
        }
    }, [windowWidth]);

    const isDTC = () => {
        return pathname.startsWith(`/${RouteNames.DTC}/`) || pathname === `/${RouteNames.DTC}`;
    };

    const isNPC = () => {
        return !isDTC();
    };

    const getMeadowProduct = () => {
        return isDTC() ? MeadowProduct.DTC : MeadowProduct.NPC;
    };

    const getSchoolBranding = (): SchoolBranding => {
        if (!school || !school.branding || isDTC()) {
            const defaultBranding: SchoolBranding = { logo: "", colorHex: MEADOW_BLUE };
            if (isDTC() && isMobile) {
                defaultBranding.logo = "/assets/logos/dtc-header-logo-mobile.svg";
                defaultBranding.logoWidth = DEFAULT_LOGO_WIDTH.dtcMobile;
            } else if (isDTC() && !isMobile) {
                defaultBranding.logo = "/assets/logos/meadow-black.svg";
                defaultBranding.logoWidth = DEFAULT_LOGO_WIDTH.dtcDesktop;
            } else {
                defaultBranding.logo = "/assets/logos/meadow-light.svg";
                defaultBranding.logoWidth = DEFAULT_LOGO_WIDTH.npc;
            }

            return defaultBranding;
        }

        if (!school.branding.logoWidth) {
            school.branding.logoWidth = DEFAULT_LOGO_WIDTH.npc;
        }

        if (!school.branding.colorHex) {
            school.branding.colorHex = MEADOW_BLUE;
        }

        return school.branding;
    };

    return {
        isDTC,
        isNPC,
        getMeadowProduct,
        getSchoolBranding,
        isMobile,
        isTablet,
    };
};

export default useSystem;
