export enum RouteNames {
    SELF = "/",
    ANY = "*",
    LOGOUT = "logout",
    PLAYGROUND = "playground",
    PLAYGROUND_LOGIN_ADMIN = "playground/login/admin",
    PLAYGROUND_LOGIN_STUDENT = "playground/login/student",
    PLAYGROUND_DEBUG = "playground/debug",
    ONBOARDING = "onboarding",
    CALCULATOR = "calculator",
    REVIEW = "review",
    CALCULATOR_CONFIG = "config",

    // dtc
    DTC = "plan",
    MOBILE_SEARCH = "search",
    SAVED_SCHOOLS = "saved",
    ESTIMATE = "estimate",
    ABOUT = "about",

    // admin
    MEADOW_ADMIN = "admin",
    NEW_SCHOOL = "school/new",
    SCHOOL_DETAILS = "school/:schoolId",
    CALCULATOR_DETAILS = "school/:schoolId/calculator/:calculatorId",
    FORMULA_DETAILS = "school/:schoolId/formula/:calculatorId",
}
