import { createContext, Dispatch } from "react";

export enum AuthReducerActionTypes {
    SET_LOGIN_TYPE = "SET_LOGIN_TYPE",
}

export enum AuthReducerLoginTypes {
    SIGN_IN = "SIGN_IN",
    SIGN_IN_OLD_ESTIMATE = "SIGN_IN_OLD_ESTIMATE",
    SIGN_UP = "SIGN_UP",
}

export type AuthReducerAction = {
    type: AuthReducerActionTypes.SET_LOGIN_TYPE;
    payload: AuthReducerLoginTypes | null;
};

export type AuthReducerState = {
    loginType: AuthReducerLoginTypes | null;
};

export const authReducerInitialState: AuthReducerState = {
    loginType: AuthReducerLoginTypes.SIGN_IN,
};

export const AuthContext = createContext<{
    state: AuthReducerState;
    dispatch: Dispatch<AuthReducerAction>;
}>({
    state: authReducerInitialState,
    dispatch: () => null,
});

export const authReducer = (state: AuthReducerState, action: AuthReducerAction) => {
    switch (action.type) {
        case AuthReducerActionTypes.SET_LOGIN_TYPE: {
            return {
                ...state,
                loginType: action.payload,
            };
        }

        default:
            return state;
    }
};
