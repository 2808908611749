export enum MeadowProduct {
    DTC = "DTC",
    NPC = "NPC",
    NPC_ADMIN = "NPCAdmin",
}

export enum Language {
    ENGLISH = "en",
    SPANISH = "es",
}

export enum SectionLabels {
    CALCULATOR = "refine.header.title",
}

export enum SectionEmojis {
    ONBOARDING = "👋",
    CALCULATOR = "💸",
}

export enum MeadowLogos {
    BLACK = "/assets/logos/meadow-black.svg",
}

export enum DTCIcons {
    FIND_SCHOOL = "/assets/icons/big/find-icon.svg",
    CHECKLIST = "/assets/icons/big/compliance.svg",
    GRADUATION = "/assets/icons/big/graduation.svg",
    PIGGY_BANK = "/assets/icons/big/piggy_bank.svg",
    SCHOOLS = "/assets/icons/big/schools.svg",
}

export enum BgImages {
    ASTERISK_BLACK = "/assets/images/bg-asterisk-black.svg",
    ASTERISK_GREEN = "/assets/images/bg-asterisk-green.svg",
    ASTERISK_WHITE = "/assets/images/bg-asterisk-white.svg",
    MOON_GREEN = "/assets/images/bg-moon-green.svg",
    MOON_BLACK = "/assets/images/bg-moon-black.svg",
    SPARKLE_BLUE = "/assets/images/bg-sparkle-blue.svg",
    SPARKLE_BLACK = "/assets/images/bg-sparkle-black.svg",
    SPARKLE_GREEN = "/assets/images/bg-sparkle-green.svg",
    SPARKLE_WHITE = "/assets/images/bg-sparkle-white.svg",
}

export enum BooleanCardImages {
    MILITARY = "/assets/images/bc-army",
    MARITAL = "/assets/images/bc-married",
    DEPENDENT = "/assets/images/bc-dependent",
    EFC = "/assets/images/bc-efc",
}

export enum BackButtonSrcs {
    ENABLED = "/assets/images/back-icon.svg",
    DISABLED = "/assets/images/back-icon-disabled.svg",
}

export enum SearchIconSrcs {
    BLACK = "/assets/images/header-search-icon.svg",
    GRAY = "/assets/images/header-search-icon-gray.svg",
}

export enum BooleanValues {
    TRUE = "TRUE",
    FALSE = "FALSE",
}

export enum SocialLogos {
    FACEBOOK = "/assets/logos/social-fb.svg",
    INSTAGRAM = "/assets/logos/social-ig.svg",
    LINKEDIN = "/assets/logos/social-in.svg",
}

export enum ActionTypes {
    UPDATE_DATA = "UPDATE_DATA",
    SET_DATA = "SET_DATA",
}

export enum DTCCardBgImages {
    URBAN = "url(/assets/images/dtc-urban.png)",
    NOT_URBAN = "url(/assets/images/dtc-rural.png)",
}
