import {
    AmountFormulaComparison,
    AmountFormulaOperator,
    AmountFormulaTypeOfResult,
} from "@meadow-fi/price-libraries/enums/amount-formula";
import {
    FieldStatus,
    MessagingKeys,
    MessagingType,
} from "@meadow-fi/price-libraries/enums/calculator";
import {
    CitizenshipStatus,
    MaritalStatus,
    ParentsResidency,
    QuestionPerspective,
} from "@meadow-fi/price-libraries/enums/onboarding";
import {
    AmountFormulaAction,
    AmountFormulaCondition,
    AmountFormulaResultAID,
} from "@meadow-fi/price-libraries/interfaces/amount-formula";
import {
    CustomField,
    CustomFieldType,
    LocalizedOption,
} from "@meadow-fi/price-libraries/interfaces/calculator-custom";
import { DTCNetPriceEstimate } from "@meadow-fi/price-libraries/interfaces/dtc-net-price";
import { Onboarding, Siblings } from "@meadow-fi/price-libraries/interfaces/onboarding";
import { MessagingConfig } from "@meadow-fi/price-libraries/interfaces/school";
import { Student } from "@meadow-fi/price-libraries/interfaces/student";
import { OnboardingReducerStateData } from "../contexts/onboarding";
import { BooleanOptionValues } from "../enums/form";
import { mapOnboardingReducerToStudentOnboarding } from "../utils/mapToApi";

export const DEFAULT_BIRTHDATE = new Date("01/01/2005");
export const EMPTY_ONBOARDING_DATA: OnboardingReducerStateData = {
    personal: {
        state: "",
        birthday: DEFAULT_BIRTHDATE,
        citizenshipStatus: CitizenshipStatus.CITIZEN,
        hasMilitaryStatus: false,
        maritalStatus: false,
        isSpouseEnrolled: BooleanOptionValues.NO,
        dependentChildren: 0,
        dependentChildrenEnrolled: 0,
    },
    family: {
        parentsMaritalStatus: MaritalStatus.MARRIED,
        parentsResidency: ParentsResidency.USA,
        siblings: [] as unknown as [Siblings],
        householdMembers: 3,
        householdMembersEnrolled: 1,
    },
    financial: {
        isEFCKnown: false,
        knownEFC: {
            efc: "",
            amountAffordPerYear: "",
        },

        manualEFC: {
            parents: {
                incomeParent1: "",
                incomeParent2: "",
                amountAffordPerYear: "",
                savingsBalance: "",
                investmentProperties: "",
                businessValue: "",
            },
            student: {
                savingsBalance: "",
                annualIncome: undefined,
                spouseAnnualIncome: undefined,
            },
        },
    },

    hasDependentChildren: false,
    hasParentsBusiness: false,
    hasParentsProperties: false,
    hasParentsSavings: false,
    hasStudentSavings: false,
    siblingsCount: 0,
    siblingsCountEnrolled: 0,
    householdMembers: 3,
    householdMembersEnrolled: 1,
    efc: 0,
    hasFinishedOnboarding: false,
};

export const EMPTY_ONBOARDING: Onboarding =
    mapOnboardingReducerToStudentOnboarding(EMPTY_ONBOARDING_DATA);

export const EMPTY_STUDENT: (student?: Partial<Student>) => Student = (student = {}) => ({
    schemaVersion: 1,
    questionPerspective: QuestionPerspective.STUDENT,
    email: "test@meadowfi.com",
    onboarding: EMPTY_ONBOARDING,
    hasFinishedOnboarding: Boolean(student.hasFinishedOnboarding),
    isAuthenticatedUser: false,
    ...student,
});

export const EMPTY_DTC_NET_PRICE_ESTIMATE: DTCNetPriceEstimate = {
    costOfAttendance: {
        roomAndBoard: 0,
        tuitionAndFees: 0,
        booksAndSupplies: 0,
        otherExpenses: 0,
        total: 0,
    },
    grantsAndScholarships: {
        grants: {
            isCalculated: false,
            federalPellGrant: 0,
            institutionalGrant: 0,
            stateGrant: 0,
            meritAid: 0,
            total: 0,
        },
        externalScholarships: { total: 0 },
        total: 0,
    },
    yourFinancing: {
        loans: { total: 0 },
        outOfPocket: { total: 0 },
        other: {
            militaryBenefits: 0,
            workStudy: 0,
            employmentContribution: 0,
            total: 0,
        },
        total: 0,
    },
    netPrice: 0,
    stillToCover: 0,
};

// ADMIN FORMULA BUILDER
export const EMPTY_CONDITION: AmountFormulaCondition = {
    operator: AmountFormulaOperator.ACT,
    comparison: AmountFormulaComparison.EQUALS,
    low: "",
};

export const EMPTY_ACTION: AmountFormulaAction = {
    conditions: [EMPTY_CONDITION],
};

export const EMPTY_RESULT: AmountFormulaResultAID = {
    typeOfAID: AmountFormulaTypeOfResult.FIXED,
    value: 0,
};

export const EMPTY_CUSTOM_FIELDS: CustomField = {
    label: { en_US: "", es_US: "" },
    renderingOptions: { prefersFullRowLayout: true },
    status: FieldStatus.HIDDEN,
    value: { options: [], defaultValue: "" },
    type: CustomFieldType.DROPDOWN_SELECT,
    variableName: "",
};

export const EMPTY_CUSTOM_DROPDOWN: LocalizedOption = {
    label: { en_US: "", es_US: "" },
    value: "",
};

export const EMPTY_MESSAGING_LABEL_CONFIG: MessagingConfig = {
    identifier: MessagingKeys.REFINE_FIELDS,
    variableName: "",
    type: MessagingType.TOOLTIP,
    value: {
        en_US: "",
        es_US: "",
    },
};
