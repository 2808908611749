import { Container as MuiContainer, Grid, Hidden, SxProps, Typography } from "@mui/material";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import BgImage from "../components/custom/BgImage";
import Container from "../components/custom/Container";
import { BgImages } from "../enums/all";
import { PRIMARY_100, TYPOGRAPHY_50 } from "../theme/colors";
import { HEADER_HEIGHT } from "../theme/sizes";
import { ApiError } from "../types/api";

type ErrorPageProps = {
    apiError?: ApiError;
    isChunkLoadError?: boolean;
};

const innerGridItemFullHeightScrollableSx: SxProps = {
    maxHeight: "100%",
    overflowY: "auto",
    pb: 3,
};

const ErrorPage = ({ apiError, isChunkLoadError }: ErrorPageProps) => {
    const CHUNK_ERROR_PAGE_RELOADED = "chunkErrorPageReloaded";

    const { t } = useTranslation("general");
    const errCode = (() => {
        if (isChunkLoadError) {
            return undefined;
        }
        return apiError ? apiError.code : 404;
    })();

    const messageI18nKey = (() => {
        if (isChunkLoadError) {
            return "error.chunkLoadError";
        }
        return errCode === 404 ? "error.404Message" : "error.genericMessage";
    })();

    const detailsStyle = { color: TYPOGRAPHY_50, sx: { mt: 1 } };

    useEffect(() => {
        if (isChunkLoadError && !sessionStorage.getItem(CHUNK_ERROR_PAGE_RELOADED)) {
            // when we update the environment, old sessions will try to access files that don't exist anymore
            // the only way to recover at this point is to reload the page.
            sessionStorage.setItem(CHUNK_ERROR_PAGE_RELOADED, "true");
            window.location.reload();
        }
    }, [isChunkLoadError]);

    const showErrorDetails = () => {
        return (
            <>
                {errCode && (
                    <Typography variant="body2" {...detailsStyle}>
                        {t("error.errorCode")}: {errCode}
                    </Typography>
                )}
                {apiError?.message && (
                    <Typography variant="body2" {...detailsStyle}>
                        {t("error.serverMessage")}: {apiError.message}
                    </Typography>
                )}
                {apiError?.requestId && (
                    <Typography variant="body2" {...detailsStyle}>
                        {t("error.requestId")}: {apiError.requestId}
                    </Typography>
                )}
            </>
        );
    };

    return (
        <MuiContainer disableGutters maxWidth={false} id="background-container">
            <BgImage
                src={BgImages.ASTERISK_GREEN}
                sx={{ position: "absolute", left: "10%", bottom: "15%" }}
            />
            <BgImage
                src={BgImages.SPARKLE_GREEN}
                sx={{ position: "absolute", left: "30%", top: "10%" }}
            />
            <Hidden mdDown>
                <BgImage
                    src={BgImages.SPARKLE_BLACK}
                    sx={{ position: "absolute", right: "30%", top: "30%" }}
                />
            </Hidden>
            <BgImage
                src={BgImages.MOON_GREEN}
                sx={{ position: "absolute", right: "10%", top: "25%" }}
            />
            <Container
                withHeader
                sx={{
                    height: "100vh",
                    maxHeight: "100vh",
                }}
            >
                <Grid
                    container
                    sx={{
                        height: { xs: "100%", md: `calc(100% - ${HEADER_HEIGHT}px)` },
                        maxHeight: { xs: "100%", md: `calc(100% - ${HEADER_HEIGHT}px)` },
                    }}
                    alignItems={"center"}
                    textAlign={"center"}
                    justifyContent={"center"}
                >
                    <Grid item sx={innerGridItemFullHeightScrollableSx}>
                        <Typography variant="h1" fontSize={"6.7rem"}>
                            🙈
                        </Typography>
                        <Typography variant="h2">{t("error.title")}</Typography>
                        <Typography variant="body1" sx={{ mt: 2 }} maxWidth={572}>
                            <Trans i18nKey={messageI18nKey} ns={"general"}>
                                It looks like you are trying to reach a page that does not exist.
                                You can go back or
                                <a
                                    style={{ color: PRIMARY_100, cursor: "pointer" }}
                                    href="mailto:support@meadowfi.com"
                                >
                                    contact us
                                </a>
                                if you need a hand.
                            </Trans>
                        </Typography>
                        {showErrorDetails()}
                    </Grid>
                </Grid>
            </Container>
        </MuiContainer>
    );
};

export default ErrorPage;
