import {
    OnboardingFamily,
    OnboardingFinancial,
    OnboardingPersonal,
    ParentsIncome,
    Siblings,
    StudentIncome,
} from "@meadow-fi/price-libraries/interfaces/onboarding";
import { Student } from "@meadow-fi/price-libraries/interfaces/student";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import { createContext, Dispatch } from "react";
import { NavigateFunction } from "react-router-dom";
import { ONBOARDING_STEPS_NAV } from "../components/onboarding/steps/navigation";
import { ActionTypes } from "../enums/all";
import { BooleanOptionValues } from "../enums/form";
import {
    OnboardingButtonAction,
    OnboardingStateDataIds,
    ReviewSubStepIds,
} from "../enums/onboarding";
import { NpcLocationData, NpcLocationGetDataUpdateKeys } from "../hooks/useNpcLocation";
import { Modify } from "../types/helpers";
import { AllSubStepIds } from "../types/onboarding";
import { Logging } from "../utils/Logging";
import { mapApiStudentToOnboardingStateData } from "../utils/mapFromApi";
import { mapOnboardingReducerToStudentOnboarding } from "../utils/mapToApi";

export enum OnboardingReducerActionTypes {
    SET_STUDENT = "SET_STUDENT",
    UPDATE_STEPPER = "UPDATE_STEPPER",
    CLEAR_ALL = "CLEAR_ALL",
    SET_IS_NEW = "SET_IS_NEW",
    SET_BIRTHDAY_ERRORED = "SET_BIRTHDAY_ERRORED",
    SET_STATE_ERRORED = "SET_STATE_ERRORED",
}

export type OnboardingReducerState = {
    data: OnboardingReducerStateData;
    student: Student;
    stepper: OnboardingReducerStateStepper;
    isLoading: boolean;
    isNew: boolean;
    dataDiff: { key: string & OnboardingStateDataIds; value: any }[];
    actionsCount: number;
    isBirthdayErrored: boolean;
    isStateErrored: boolean;
};

export type OnboardingReducerAction =
    | {
          type: OnboardingReducerActionTypes.UPDATE_STEPPER;
          payload: Partial<OnboardingReducerStateStepper>;
          btnAction: OnboardingButtonAction;
          navigate?: NavigateFunction;
          getNpcLocationData?: (update?: {
              [key in NpcLocationGetDataUpdateKeys]?: any;
          }) => NpcLocationData;
      }
    | {
          type: ActionTypes.UPDATE_DATA;
          payload: Partial<OnboardingReducerStateData> | { [key in OnboardingStateDataIds]?: any };
      }
    | {
          type: ActionTypes.SET_DATA;
          payload: OnboardingReducerStateData;
      }
    | {
          type: OnboardingReducerActionTypes.SET_STUDENT;
          payload: Student;
      }
    | {
          type: OnboardingReducerActionTypes.SET_IS_NEW;
          payload: boolean;
      }
    | {
          type: OnboardingReducerActionTypes.CLEAR_ALL;
      }
    | {
          type: OnboardingReducerActionTypes.SET_BIRTHDAY_ERRORED;
          payload: boolean;
      }
    | {
          type: OnboardingReducerActionTypes.SET_STATE_ERRORED;
          payload: boolean;
      };

export type OnboardingReducerStateData = {
    personal: Modify<
        OnboardingPersonal,
        {
            maritalStatus: boolean;
            isSpouseEnrolled?: BooleanOptionValues;
        }
    >;
    family: Modify<
        OnboardingFamily,
        {
            siblings: [Siblings];
            householdMembers: number;
            householdMembersEnrolled: number;
        }
    >;
    financial: Modify<
        OnboardingFinancial,
        {
            knownEFC?: ModifiedFinancialKnownEFC;
            manualEFC?: ModifiedFinancialManualEFC;
        }
    >;
    efc?: number;

    // control
    hasDependentChildren: boolean;
    siblingsCount: number;
    siblingsCountEnrolled: number;
    householdMembers: number;
    householdMembersEnrolled: number;
    hasParentsSavings: boolean;
    hasParentsProperties: boolean;
    hasParentsBusiness: boolean;
    hasStudentSavings: boolean;
    hasFinishedOnboarding: boolean;
};

type ModifiedFinancialKnownEFC = {
    efc: string;
    amountAffordPerYear: string;
};

type ModifiedFinancialManualEFC = {
    parents?: ModifiedParentsIncome;
    student: ModifiedStudentIncome;
};

type ModifiedParentsIncome = Modify<
    ParentsIncome,
    {
        incomeParent1: string;
        incomeParent2: string;
        amountAffordPerYear: string;
        savingsBalance: string;
        investmentProperties: string;
        businessValue: string;
    }
>;

type ModifiedStudentIncome = Modify<
    StudentIncome,
    {
        annualIncome: number | undefined;
        spouseAnnualIncome: number | undefined;
        savingsBalance: string;
    }
>;

export type OnboardingReducerStateStepper = {
    activeStep: number;
    activeSubStep: AllSubStepIds;
};

export const onboardingReducerInitialState: OnboardingReducerState = {
    stepper: ONBOARDING_STEPS_NAV.BIRTHDAY_CITIZENSHIP,
    data: {} as OnboardingReducerStateData,
    dataDiff: [],
    actionsCount: 0,
    student: {} as Student,
    isNew: false,
    isLoading: false,
    isBirthdayErrored: false,
    isStateErrored: false,
};

export const onboardingReducer = (
    state: OnboardingReducerState,
    action: OnboardingReducerAction
) => {
    switch (action.type) {
        case ActionTypes.SET_DATA: {
            const student = cloneDeep(state.student);
            student.onboarding = mapOnboardingReducerToStudentOnboarding(action.payload);
            student.hasFinishedOnboarding = action.payload.hasFinishedOnboarding;

            return {
                ...state,
                student,
                data: cloneDeep(action.payload),
            };
        }

        case OnboardingReducerActionTypes.SET_STUDENT: {
            return {
                ...state,
                student: {
                    ...action.payload,
                    onboarding: {
                        ...action.payload.onboarding,
                        personal: {
                            ...action.payload.onboarding.personal,
                            birthday: new Date(action.payload.onboarding.personal.birthday),
                        },
                    },
                },
                data: mapApiStudentToOnboardingStateData(action.payload),
                isNew: !action.payload.hasFinishedOnboarding,
            };
        }

        case ActionTypes.UPDATE_DATA: {
            // only updated one field
            const key = Object.keys(action.payload)[0] as OnboardingStateDataIds;
            const value = cloneDeep(Object.values(action.payload)[0]);
            const updatedData: OnboardingReducerStateData = cloneDeep(set(state.data, key, value));

            const dataDiff = cloneDeep(state.dataDiff || []);
            dataDiff.push({ key, value });
            return {
                ...state,
                data: updatedData,
                student: {
                    ...state.student,
                    hasFinishedOnboarding: updatedData.hasFinishedOnboarding,
                    onboarding: mapOnboardingReducerToStudentOnboarding(updatedData),
                },
                dataDiff,
                actionsCount: state.actionsCount + 1,
            };
        }

        case OnboardingReducerActionTypes.UPDATE_STEPPER: {
            Logging.onboardingEndPage(
                state.stepper.activeSubStep,
                action.btnAction,
                state.dataDiff,
                state.student
            );
            if (action.payload.activeSubStep == ReviewSubStepIds.REVIEW_EFC) {
                Logging.finishOnboarding(state.student, state.actionsCount);
            } else {
                Logging.onboardingStartPage();
            }

            if (action.navigate && action.getNpcLocationData) {
                const locationState = action.getNpcLocationData({
                    [NpcLocationGetDataUpdateKeys.STEPPER]: action.payload,
                });
                action.navigate(".", { state: locationState });
            }

            return {
                ...state,
                stepper: cloneDeep({ ...state.stepper, ...action.payload }),
                dataDiff: [],
            };
        }

        case OnboardingReducerActionTypes.SET_IS_NEW: {
            return {
                ...state,
                isNew: action.payload,
            };
        }

        case OnboardingReducerActionTypes.CLEAR_ALL: {
            return onboardingReducerInitialState;
        }

        case OnboardingReducerActionTypes.SET_BIRTHDAY_ERRORED: {
            return {
                ...state,
                isBirthdayErrored: action.payload,
            };
        }

        case OnboardingReducerActionTypes.SET_STATE_ERRORED: {
            return {
                ...state,
                isStateErrored: action.payload,
            };
        }

        default:
            return state;
    }
};

export const OnboardingContext = createContext<{
    state: OnboardingReducerState;
    dispatch: Dispatch<OnboardingReducerAction>;
}>({
    state: onboardingReducerInitialState,
    dispatch: () => null,
});
