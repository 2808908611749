import { useAuth0 } from "@auth0/auth0-react";
import { Backdrop, CircularProgress } from "@mui/material";
import { lazy, Suspense, useReducer } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    AppContext,
    appReducer,
    AppReducerActionTypes,
    appReducerInitialState,
} from "../../contexts/app";
import { AuthContext, authReducer, authReducerInitialState } from "../../contexts/auth";
import {
    ModalContext,
    modalReducer,
    ModalReducerActionTypes,
    modalReducerInitialState,
} from "../../contexts/modal";
import {
    NetPriceCalculatorContext,
    netPriceCalculatorReducer,
    netPriceCalculatorReducerInitialState,
} from "../../contexts/netPriceCalculator";
import {
    OnboardingContext,
    onboardingReducer,
    onboardingReducerInitialState,
} from "../../contexts/onboarding";
import { SchoolContext, schoolReducer, schoolReducerInitialState } from "../../contexts/school";
import { UserContext, userReducer, UserReducerInitialState } from "../../contexts/user";
import { PRIMARY_100 } from "../../theme/colors";

const GoToLoginModal = lazy(() => import("../../components/modals/GoToLoginModal"));
const RecoverEstimateModal = lazy(() => import("../../components/modals/RecoverEstimateModal"));

const Main = () => {
    const { isLoading } = useAuth0();

    const [appState, appDispatch] = useReducer(appReducer, appReducerInitialState);
    const [authState, authDispatch] = useReducer(authReducer, authReducerInitialState);
    const [modalState, modalDispatch] = useReducer(modalReducer, modalReducerInitialState);
    const [schoolState, schoolDispatch] = useReducer(schoolReducer, schoolReducerInitialState);
    const [onboardingState, onboardingDispatch] = useReducer(
        onboardingReducer,
        onboardingReducerInitialState
    );
    const [netPriceCalculatorState, netPriceCalculatorDispatch] = useReducer(
        netPriceCalculatorReducer,
        netPriceCalculatorReducerInitialState
    );
    const [userState, userDispatch] = useReducer(userReducer, UserReducerInitialState);

    return (
        <AppContext.Provider value={{ state: appState, dispatch: appDispatch }}>
            <AuthContext.Provider value={{ state: authState, dispatch: authDispatch }}>
                <UserContext.Provider value={{ state: userState, dispatch: userDispatch }}>
                    <ModalContext.Provider value={{ state: modalState, dispatch: modalDispatch }}>
                        <SchoolContext.Provider
                            value={{ state: schoolState, dispatch: schoolDispatch }}
                        >
                            <OnboardingContext.Provider
                                value={{ state: onboardingState, dispatch: onboardingDispatch }}
                            >
                                <NetPriceCalculatorContext.Provider
                                    value={{
                                        state: netPriceCalculatorState,
                                        dispatch: netPriceCalculatorDispatch,
                                    }}
                                >
                                    <Outlet />

                                    <Suspense fallback={<></>}>
                                        <GoToLoginModal
                                            open={
                                                isLoading &&
                                                !!appState.popupWindow &&
                                                !appState.popupWindow.closed
                                            }
                                            popupWindow={appState.popupWindow!}
                                            onClose={() =>
                                                appDispatch?.({
                                                    type: AppReducerActionTypes.SET_POPUP_WINDOW,
                                                    payload: null,
                                                })
                                            }
                                        />
                                        <RecoverEstimateModal
                                            data={modalState.recoverEstimateModalData}
                                            open={modalState.recoverEstimateModalOpen}
                                            onClose={() =>
                                                modalDispatch({
                                                    type: ModalReducerActionTypes.TOGGLE_RECOVER_ESTIMATE_MODAL,
                                                    payload: false,
                                                })
                                            }
                                        />
                                    </Suspense>

                                    <ToastContainer
                                        position="top-left"
                                        progressStyle={{ background: PRIMARY_100 }}
                                        bodyStyle={{ fontFamily: "Open Sans", fontSize: ".875rem" }}
                                    />

                                    <Backdrop open={appState.showBackdrop}>
                                        <CircularProgress />
                                    </Backdrop>
                                </NetPriceCalculatorContext.Provider>
                            </OnboardingContext.Provider>
                        </SchoolContext.Provider>
                    </ModalContext.Provider>
                </UserContext.Provider>
            </AuthContext.Provider>
        </AppContext.Provider>
    );
};

export default Main;
