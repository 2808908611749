import { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
    DTCNetPriceCalculatorContext,
    DTCNetPriceCalculatorReducerState,
} from "../../contexts/DTCNetPriceCalculator";
import { OnboardingContext } from "../../contexts/onboarding";
import { ActionTypes, MeadowProduct } from "../../enums/all";
import useDTCGuest from "../../hooks/useDTCGuest";
import useNpcLocation, { NpcLocationData } from "../../hooks/useNpcLocation";
import { Logging } from "../../utils/Logging";
import { mapApiStudentToOnboardingStateData } from "../../utils/mapFromApi";

const VerifyDTCStudent = () => {
    const location = useLocation();
    const locationState = location.state as NpcLocationData;

    const { isValidLocationState } = useNpcLocation();

    const { dispatch: onboardingDispatch } = useContext(OnboardingContext);
    const { state: DTCState, dispatch: DTCDispatch } = useContext(DTCNetPriceCalculatorContext);
    const { readStudent } = useDTCGuest();

    useEffect(() => {
        Logging.registerProduct(MeadowProduct.DTC);

        // if has location data on refresh, set it to onbarding/dtc state
        if (isValidLocationState(locationState)) {
            if (
                locationState.onboardingState?.data &&
                Object.keys(locationState.onboardingState.data).length
            ) {
                onboardingDispatch({
                    type: ActionTypes.SET_DATA,
                    payload: locationState.onboardingState.data,
                });
            }
            if (
                locationState.netPriceCalculatorState?.data &&
                Object.keys(locationState.netPriceCalculatorState.data).length &&
                !DTCState.isLoading
            ) {
                DTCDispatch({
                    type: ActionTypes.SET_DATA,
                    payload: (
                        locationState.netPriceCalculatorState as DTCNetPriceCalculatorReducerState
                    ).data,
                });
            }
        } else {
            const student = readStudent();
            if (student) {
                // if we have saved student, let's add it's info to context
                onboardingDispatch({
                    type: ActionTypes.SET_DATA,
                    payload: mapApiStudentToOnboardingStateData(student),
                });
            }
        }
    }, []);

    return <Outlet />;
};

export default VerifyDTCStudent;
