import { Breakpoint, Hidden, SxProps } from "@mui/material";
import { lazy } from "react";
import { IHeader } from "../../../interfaces/layout";

// 69kb to main
const ContainerDesktop = lazy(() => import("./Desktop"));
const ContainerMobile = lazy(() => import("./Mobile"));

export interface IContainer {
    children?: JSX.Element | JSX.Element[];
    withHeader?: boolean;
    headerProps?: IHeader;
    maxWidth?: Breakpoint;
    sx?: SxProps;
}

const Container = (props: IContainer): JSX.Element => {
    return (
        <>
            <Hidden mdUp initialWidth="md">
                <ContainerMobile {...props} />
            </Hidden>
            <Hidden mdDown initialWidth="md">
                <ContainerDesktop {...props} />
            </Hidden>
        </>
    );
};

export default Container;
