import { Box, SxProps } from "@mui/material";
import { BgImages } from "../../enums/all";

type BgImageProps = {
    src: BgImages;
    sx: SxProps;
};

const BgImage = ({ src, sx }: BgImageProps) => {
    sx = sx || {};

    return (
        <Box sx={{ userSelect: "none", ...sx }}>
            <img alt="" width="100%" height="100%" src={src} />
        </Box>
    );
};

export default BgImage;
