export enum OnboardingStepIds {
    PERSONAL = "personal",
    FAMILY = "family",
    FINANCIAL = "financial",
    REVIEW_EFC = "review",
}

export enum OnboardingStepLabels {
    PERSONAL = "personal.label",
    FAMILY = "family.label",
    FINANCIAL = "financial.label",
    REVIEW_EFC = "reviewEFC.label",
}

export enum PersonalSubStepIds {
    BIRTHDAY_CITIZENSHIP = "BIRTHDAY_CITIZENSHIP",
    INFO_CARDS = "INFO_CARDS",
}

export enum FamilySubStepIds {
    PMARITAL_SIBLINGS = "PMARITAL_SIBLINGS",
    MARITAL = "MARITAL",
    DEPENDENT = "DEPENDENT",
}

export enum FinancialSubStepIds {
    EFC = "EFC",
    PARENT_FINANCES_1 = "PARENT_FINANCES_1",
    PARENT_FINANCES_2 = "PARENT_FINANCES_2",
    STUDENT_FINANCES = "STUDENT_FINANCES",
}

export enum ReviewSubStepIds {
    REVIEW_EFC = "REVIEW_EFC",
}

export enum OnboardingStateDataIds {
    BIRTHDAY = "personal.birthday",
    CITIZENSHIP_STATUS = "personal.citizenshipStatus",
    STATE = "personal.state",
    HAS_MILITARY_STATUS = "personal.hasMilitaryStatus",
    MARITAL_STATUS = "personal.maritalStatus",
    HAS_DEPENDENT_CHILDREN = "hasDependentChildren",
    IS_EFC_KNOWN = "financial.isEFCKnown",

    PARENTS_MARITAL_STATUS = "family.parentsMaritalStatus",
    SIBLINGS_COUNT = "siblingsCount",
    SIBLINGS_COUNT_ENROLLED = "siblingsCountEnrolled",
    HOUSEHOLD_MEMBERS = "family.householdMembers",
    HOUSEHOLD_MEMBERS_ENROLLED = "family.householdMembersEnrolled",
    SIBLINGS = "family.siblings",
    IS_SPOUSE_ENROLLED = "personal.isSpouseEnrolled",
    DEPENDENT_CHILDREN = "personal.dependentChildren",
    DEPENDENT_CHILDREN_ENROLLED = "personal.dependentChildrenEnrolled",

    KNOWN_EFC_EFC = "financial.knownEFC.efc",
    PARENTS_INCOME_PARENT_1 = "financial.manualEFC.parents.incomeParent1",
    PARENTS_INCOME_PARENT_2 = "financial.manualEFC.parents.incomeParent2",
    HAS_PARENTS_SAVINGS = "hasParentsSavings",
    PARENTS_SAVINGS_BALANCE = "financial.manualEFC.parents.savingsBalance",
    HAS_PARENTS_PROPERTIES = "hasParentsProperties",
    PARENTS_INVESTMENT_PROPERTIES = "financial.manualEFC.parents.investmentProperties",
    HAS_PARENTS_BUSINESS = "hasParentsBusiness",
    PARENTS_BUSINESS_VALUE = "financial.manualEFC.parents.businessValue",
    STUDENT_ANNUAL_INCOME = "financial.manualEFC.student.annualIncome",
    STUDENT_SPOUSE_ANNUAL_INCOME = "financial.manualEFC.student.spouseAnnualIncome",
    HAS_STUDENT_SAVINGS = "hasStudentSavings",
    STUDENT_SAVINGS_BALANCE = "financial.manualEFC.student.savingsBalance",
    STUDENT_STATUS = "financial.manualEFC.student.status",

    BC_GROUP = "group", // for booleanCardGroup component
    ABOUT = "about", // for typography

    SUMMARY = "summary",
}

export enum OnboardingButtonAction {
    NEXT = "NEXT",
    PREVIOUS = "PREVIOUS",
}
