import { Calculator } from "@meadow-fi/price-libraries/interfaces/calculator";
import { DTCSchoolData, DTCSchoolInfo } from "@meadow-fi/price-libraries/interfaces/dtc-school";
import { School } from "@meadow-fi/price-libraries/interfaces/school";
import { createContext, Dispatch } from "react";
import { Logging } from "../utils/Logging";

export enum SchoolReducerActionTypes {
    SET_CURRENT = "SET_CURRENT",
    SET_CALCULATOR = "SET_CALCULATOR",
    SET_DTC_CURRENT = "SET_DTC_CURRENT",
    SET_SELECTED = "SET_SELECTED",
    SET_ALL = "SET_ALL",
    SET_FILTERED = "SET_FILTERED",
    SET_LOADING = "SET_LOADING",
    CLEAR_CURRENT = "CLEAR_CURRENT",
}

export type SchoolReducerAction =
    | { type: SchoolReducerActionTypes.SET_CURRENT; payload: School | null }
    | { type: SchoolReducerActionTypes.SET_DTC_CURRENT; payload: DTCSchoolData | null }
    | { type: SchoolReducerActionTypes.SET_SELECTED; payload: School | null }
    | { type: SchoolReducerActionTypes.SET_ALL; payload: School[] | null }
    | { type: SchoolReducerActionTypes.SET_FILTERED; payload: School[] | null }
    | { type: SchoolReducerActionTypes.SET_LOADING; payload: boolean }
    | { type: SchoolReducerActionTypes.SET_CALCULATOR; payload: Calculator | null }
    | { type: SchoolReducerActionTypes.CLEAR_CURRENT };

export type SchoolReducerState = {
    current: School | null;
    calculator: Calculator | null;
    schoolInfo: DTCSchoolInfo | null;
    selected: School | null;
    all: School[] | null;
    filtered?: School[] | null;

    isLoading: boolean;
    error: string | null;
};

export const schoolReducerInitialState: SchoolReducerState = {
    current: null,
    calculator: null,
    schoolInfo: null,
    selected: null,
    all: null,
    filtered: null,
    isLoading: false,
    error: null,
};

export const SchoolContext = createContext<{
    state: SchoolReducerState;
    dispatch: Dispatch<SchoolReducerAction>;
}>({
    state: schoolReducerInitialState,
    dispatch: () => null,
});

export const schoolReducer = (state: SchoolReducerState, action: SchoolReducerAction) => {
    switch (action.type) {
        case SchoolReducerActionTypes.SET_CURRENT: {
            if (action.payload) {
                Logging.identifySchool(action.payload.id);
            }
            return {
                ...state,
                current: action.payload,
                error: null,
                isLoading: false,
            };
        }
        case SchoolReducerActionTypes.SET_CALCULATOR: {
            return {
                ...state,
                calculator: action.payload,
                error: null,
                isLoading: false,
            };
        }
        case SchoolReducerActionTypes.SET_DTC_CURRENT: {
            if (action.payload) {
                Logging.identifySchool(action.payload.adaptedSchool.id);
                Logging.dtcSchoolView(
                    action.payload.schoolInfo.unitid!,
                    action.payload.adaptedSchool.name
                );
                return {
                    ...state,
                    current: action.payload.adaptedSchool,
                    schoolInfo: action.payload.schoolInfo,
                    error: null,
                    isLoading: false,
                };
            }
            return state;
        }
        case SchoolReducerActionTypes.SET_SELECTED: {
            return {
                ...state,
                selected: action.payload,
                error: null,
                isLoading: false,
            };
        }
        case SchoolReducerActionTypes.SET_ALL: {
            return {
                ...state,
                all: action.payload,
                error: null,
                isLoading: false,
            };
        }
        case SchoolReducerActionTypes.SET_FILTERED: {
            return {
                ...state,
                filtered: action.payload,
                error: null,
                isLoading: false,
            };
        }
        case SchoolReducerActionTypes.SET_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }

        case SchoolReducerActionTypes.CLEAR_CURRENT: {
            return {
                ...state,
                current: null,
                schoolInfo: null,
            };
        }

        default:
            return state;
    }
};
