import { DEFAULT_DTC_NET_PRICE } from "@meadow-fi/price-libraries/defaults/dtc-net-price";
import { DEFAULT_NET_PRICE } from "@meadow-fi/price-libraries/defaults/net-price";
import { SchoolStatus } from "@meadow-fi/price-libraries/enums/school";
import { StudentUtil } from "@meadow-fi/price-libraries/utils/StudentUtil";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ONBOARDING_STEPS_NAV } from "../components/onboarding/steps/navigation";
import { EMPTY_ONBOARDING_DATA } from "../constants/empty";
import {
    DTCNetPriceCalculatorContext,
    DTCNetPriceCalculatorReducerState,
} from "../contexts/DTCNetPriceCalculator";
import {
    NetPriceCalculatorContext,
    NetPriceCalculatorReducerState,
} from "../contexts/netPriceCalculator";
import { OnboardingContext, OnboardingReducerState } from "../contexts/onboarding";
import { SchoolContext } from "../contexts/school";
import { RouteNames } from "../enums/routes";
import { IStepperNavigation } from "../interfaces/stepper";
import { NpcParamsType } from "../types/all";
import useSystem from "./useSystem";

export enum NpcLocationGetDataUpdateKeys {
    ONBOARDING_DATA = "onboardingState.data",
    STEPPER = "onboardingState.stepper",

    NPC_DATA = "netPriceCalculatorState.data",
    ENROLLMENT_STATUS = "netPriceCalculatorState.tuitionAndFees.enrollmentStatus",
    NEXT_YEAR_OF_STUDY = "netPriceCalculatorState.tuitionAndFees.nextYearOfStudy",
    NUMBER_OF_CREDITS = "netPriceCalculatorState.tuitionAndFees.numberOfCredits",
    IS_TRANSFER_STUDENT = "netPriceCalculatorState.tuitionAndFees.transferStudent",
    HOUSING = "netPriceCalculatorState.roomAndBoard.housing",
    MEALS = "netPriceCalculatorState.roomAndBoard.meals",
    TEXTBOOKS = "netPriceCalculatorState.otherExpenses.textbookAcquisition",
    TRANSPORTATION = "netPriceCalculatorState.otherExpenses.transportation",
    INCLUDE_LAPTOP = "netPriceCalculatorState.otherExpenses.includeLaptop",
    MERIT_GPA = "netPriceCalculatorState.merit.gpa",
    MERIT_STANDARDIZED_TEST = "netPriceCalculatorState.merit.standardizedTest",
    MERIT_SAT = "netPriceCalculatorState.merit.satScore",
    MERIT_ACT = "netPriceCalculatorState.merit.actScore",
    MERIT_WONDERLIC = "netPriceCalculatorState.merit.wonderlicScore",
    MERIT_ART_SCORE = "netPriceCalculatorState.merit.artScore",
    MILITARY_SERVICE_STATUS = "netPriceCalculatorState.military.serviceStatus",
    MILITARY_MONTHS_OF_SERVICE = "netPriceCalculatorState.military.monthsOfService",
    MILITARY_PURPLE_HEART_OR_MEDICAL_DISCHARGE = "netPriceCalculatorState.military.purpleHearOrMedicalDischarge",
    NEED_BASED_COMPLETED_FAFSA = "netPriceCalculatorState.needBasedAid.completedFafsaByElegibilityDate",
    NEED_BASED_GGMS_ELIGIBLE = "netPriceCalculatorState.needBasedAid.ggmsEligible",
}

export type NpcLocationData = {
    isInstructionsDone?: boolean;
    onboardingState: OnboardingReducerState;
    netPriceCalculatorState: NetPriceCalculatorReducerState | DTCNetPriceCalculatorReducerState;
};

const useNpcLocation = () => {
    const params = useParams<NpcParamsType>();
    const { state: netPriceCalculatorState } = useContext(NetPriceCalculatorContext);
    const { state: DTCState } = useContext(DTCNetPriceCalculatorContext);
    const { state: schoolState } = useContext(SchoolContext);
    const { state: onboardingState } = useContext(OnboardingContext);
    const { isNPC } = useSystem();
    const location = useLocation();

    const isValidLocationState = (locationState: any): boolean => {
        if (
            !locationState?.onboardingState ||
            !Object.keys(locationState.onboardingState).length ||
            !locationState?.netPriceCalculatorState ||
            !Object.keys(locationState.netPriceCalculatorState).length
        ) {
            return false;
        }

        return true;
    };

    const getNpcLocationData = (
        update: { [key in NpcLocationGetDataUpdateKeys]?: any } = {}
    ): NpcLocationData => {
        const clone: NpcLocationData = {
            onboardingState: cloneDeep(onboardingState),
            netPriceCalculatorState: cloneDeep(isNPC() ? netPriceCalculatorState : DTCState),
            isInstructionsDone: (location as any)?.state?.isInstructionsDone,
        };

        if (!Object.keys(clone.onboardingState?.data).length) {
            clone.onboardingState.data = EMPTY_ONBOARDING_DATA;
        }
        if (!Object.keys(clone.netPriceCalculatorState?.data).length) {
            clone.netPriceCalculatorState.data = isNPC()
                ? DEFAULT_NET_PRICE
                : DEFAULT_DTC_NET_PRICE;
        }

        if (Object.keys(update).length) {
            for (const [key, value] of Object.entries(update)) {
                set(clone, key, value);
            }
        }

        return clone;
    };

    const getNpcUrl = (isStartNewEstimate = false, hasFinishedOnboarding?: boolean): string => {
        hasFinishedOnboarding =
            typeof hasFinishedOnboarding === "boolean"
                ? hasFinishedOnboarding
                : onboardingState.student.hasFinishedOnboarding;

        const destination: RouteNames =
            hasFinishedOnboarding && !isStartNewEstimate
                ? RouteNames.CALCULATOR
                : RouteNames.ONBOARDING;

        const activeCalculators = schoolState.current?.calculators.filter(
            (calculator) => calculator.status == SchoolStatus.ACTIVE
        );
        if (params?.calculatorId) {
            return `/${schoolState.current?.id}/${params.calculatorId}/${destination}`;
        } else if (activeCalculators?.length == 1) {
            return `/${schoolState.current?.id}/${activeCalculators[0].id}/${destination}`;
        } else {
            return "";
        }
    };

    const getOnboardingSummaryEditNavigation = (
        type: "personal" | "family" | "financial"
    ): IStepperNavigation => {
        let navigation: IStepperNavigation;
        const studentUtil = new StudentUtil(onboardingState.student);

        switch (type) {
            case "personal": {
                navigation = ONBOARDING_STEPS_NAV.BIRTHDAY_CITIZENSHIP;
                break;
            }
            case "family": {
                if (!studentUtil.isIndependent()) {
                    // dependent
                    navigation = ONBOARDING_STEPS_NAV.PMARITAL_SIBLINGS;
                } else {
                    // independent
                    if (onboardingState.data.personal.maritalStatus) {
                        navigation = ONBOARDING_STEPS_NAV.MARITAL;
                    } else if (onboardingState.data.hasDependentChildren) {
                        navigation = ONBOARDING_STEPS_NAV.DEPENDENT;
                    } else {
                        navigation = ONBOARDING_STEPS_NAV.INFO_CARDS;
                    }
                }
                break;
            }
            case "financial": {
                if (!studentUtil.isIndependent()) {
                    // dependent
                    navigation = ONBOARDING_STEPS_NAV.PARENT_FINANCES_1;
                } else {
                    // independent
                    navigation = ONBOARDING_STEPS_NAV.STUDENT_FINANCES;
                }
                break;
            }

            default:
                break;
        }

        return navigation!;
    };

    return {
        isValidLocationState,
        getNpcLocationData,
        getNpcUrl,
        getOnboardingSummaryEditNavigation,
    };
};

export default useNpcLocation;
