import { Box, ThemeProvider } from "@mui/material";
import { useContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
import {
    DTCNetPriceCalculatorContext,
    DTCNetPriceCalculatorReducer,
    DTCNetPriceCalculatorReducerInitialState,
} from "../../contexts/DTCNetPriceCalculator";
import { UserContext, UserReducerActionTypes } from "../../contexts/user";
import useDTCGuest from "../../hooks/useDTCGuest";
import dtcTheme from "../../pages/DTC/theme";

const DTC = () => {
    const [dtcState, dtcDispatch] = useReducer(
        DTCNetPriceCalculatorReducer,
        DTCNetPriceCalculatorReducerInitialState
    );
    const { dispatch: userDispatch } = useContext(UserContext);
    const { savedSchools } = useDTCGuest();

    useEffect(() => {
        // the first time we're loading, let's set the saved schools to the state
        userDispatch({ type: UserReducerActionTypes.SET_SAVED_SCHOOLS, payload: savedSchools });
    }, []);

    return (
        <DTCNetPriceCalculatorContext.Provider value={{ state: dtcState, dispatch: dtcDispatch }}>
            <ThemeProvider theme={dtcTheme}>
                <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                    <Outlet />
                </Box>
            </ThemeProvider>
        </DTCNetPriceCalculatorContext.Provider>
    );
};

export default DTC;
