import { useAuth0 } from "@auth0/auth0-react";
import { GEO_LOCATION_HEADER } from "@meadow-fi/price-libraries/defaults/constants";
import axios, { AxiosRequestConfig, AxiosResponseHeaders } from "axios";
import i18n from "i18next";
import { ApiConfigOptions, ApiResponse } from "../types/api";

const host = process.env.REACT_APP_MEADOW_BACKEND_ENDPOINT;

export const API_ROOT_URL = `${host}/meadow`;

const networkCalls = () => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const parseErrorResponse = (err: any): ApiResponse => {
        const response = {} as ApiResponse;
        if (err.response?.data?.code) {
            if (err.response.data.code) {
                response.error = { ...err.response.data };
            } else {
                response.error = { code: err.response.status, message: err.response.data };
            }
            response.status = err.response.status;
        } else {
            response.error = { code: 500, message: err.message };
            response.status = 500;
        }

        return response;
    };
    const cacheHeaders = (headers: AxiosResponseHeaders) => {
        if (headers && GEO_LOCATION_HEADER in headers) {
            sessionStorage.setItem(GEO_LOCATION_HEADER, headers[GEO_LOCATION_HEADER]);
        }
    };
    const getConfig = async (options: ApiConfigOptions = {}) => {
        const headers = {
            "content-language": i18n.language,
        };

        if (options.auth || (options.authOptional && isAuthenticated)) {
            const token = await getAccessTokenSilently();
            headers["Authorization"] = `Bearer ${token}`;
        }

        const config: AxiosRequestConfig = { headers };
        if (options.responseType != undefined) {
            config.responseType = options.responseType;
        }
        return config;
    };

    const get = async (endpoint: string, configOptions: ApiConfigOptions = {}) => {
        const response = {} as ApiResponse;
        const config = await getConfig(configOptions);

        try {
            const { status, data, headers } = await axios.get(API_ROOT_URL + endpoint, config);
            cacheHeaders(headers);
            response.data = data;
            response.status = status;
        } catch (err: any) {
            throw parseErrorResponse(err);
        }

        return response;
    };

    const put = async (endpoint: string, body: any = {}, configOptions: ApiConfigOptions = {}) => {
        const response = {} as ApiResponse;
        const config = await getConfig(configOptions);

        try {
            const { status, data } = await axios.put(API_ROOT_URL + endpoint, body, config);
            response.data = data;
            response.status = status;
        } catch (err: any) {
            throw parseErrorResponse(err);
        }

        return response;
    };
    const post = async (endpoint: string, body: any = {}, configOptions: ApiConfigOptions = {}) => {
        const response = {} as ApiResponse;
        const config = await getConfig(configOptions);

        try {
            const { status, data } = await axios.post(API_ROOT_URL + endpoint, body, config);
            response.data = data;
            response.status = status;
        } catch (err: any) {
            throw parseErrorResponse(err);
        }

        return response;
    };
    const del = async (endpoint: string, configOptions: ApiConfigOptions = {}) => {
        const response = {} as ApiResponse;
        const config = await getConfig(configOptions);

        try {
            const { status, data } = await axios.delete(API_ROOT_URL + endpoint, config);
            response.data = data;
            response.status = status;
        } catch (err: any) {
            throw parseErrorResponse(err);
        }

        return response;
    };
    return {
        get,
        put,
        post,
        del,
    };
};

export default networkCalls;
