import { LocalStorageKeys } from "../enums/localStorage";

const useLocalStorage = () => {
    const get = (key: LocalStorageKeys) => {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    };

    const set = (key: LocalStorageKeys, obj: any) => {
        const value = JSON.stringify(obj);
        localStorage.setItem(key, value);
    };

    return { get, set };
};

export default useLocalStorage;
