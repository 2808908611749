export const currencyCommaReplace = (value: string | undefined): string => {
    if (!value?.replace) return "";

    return value.replace(/,/g, "");
};

export const currencyToNumber = (value: string | undefined): number => {
    const r = currencyCommaReplace(value);

    if (isNaN(Number(r))) return 0;

    return Number(r);
};

export const formatCurrency = (
    value: number,
    hideDollarSign?: boolean,
    maximumFractionDigits = 0
) => {
    let str = "";

    if (!hideDollarSign) {
        str += "$";
    }
    str += new Intl.NumberFormat("en-US", {
        currency: "USD",
        maximumFractionDigits,
    }).format(value);

    return str;
};

export const createGrantScholarshipText = (percent: string, isMobile: boolean) => {
    if (isMobile) {
        return `${percent}% of our full-time, first-year undergraduates received grant/scholarship aid in the current academic year.`;
    } else {
        return `${percent}% of our full-time, first-year undergraduates received \n grant/scholarship aid in the current academic year.`;
    }
};
