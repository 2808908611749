import { DTCSchool } from "@meadow-fi/price-libraries/interfaces/dtc-school";
import { useState } from "react";
import { DTCPagination, SearchDTCParams, SearchDTCResponse } from "../types/api";
import { Logging } from "../utils/Logging";
import useApi from "./useApi";

const useDTCSearch = () => {
    const { searchDTC, browseDTC } = useApi();

    const [searchValue, setSearchValue] = useState<string>("");
    const [searchTimeout, setSearchTimeout] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

    const [results, setResults] = useState<Partial<DTCSchool>[]>([]);
    const [pagination, setPagination] = useState<DTCPagination | null>(null);

    const browse = (params: SearchDTCParams, debounce?: number) => {
        return searchOrBrowse(params, browseDTC, debounce);
    };

    const search = (params: SearchDTCParams, debounce?: number) => {
        return searchOrBrowse(params, searchDTC, debounce);
    };

    const searchOrBrowse = (
        params: SearchDTCParams,
        useApiBrowse: (params: SearchDTCParams) => Promise<SearchDTCResponse>,
        debounce?: number
    ) => {
        setIsLoading(true);

        if (params.search) {
            setSearchValue(params.search);
        }

        const fn = async () => {
            if (params.search === "") {
                setResults([]);
                setPagination(null);
                setIsLoading(false);
                return;
            }

            try {
                const { data } = await useApiBrowse(params);
                if (params.search) {
                    Logging.dtcSchoolSearch(data.data, params.search);
                }

                setResults(data.data);
                setPagination(data.pagination);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };

        if (debounce) {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
                setSearchTimeout(null);
            }

            setSearchTimeout(setTimeout(fn, debounce));
        } else {
            fn();
        }
    };

    const loadMore = async (params: SearchDTCParams) => {
        try {
            setIsLoadingMore(true);
            const { data } = await browseDTC(params);
            setResults([...results, ...data.data]);
            setPagination(data.pagination);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoadingMore(false);
        }
    };

    const clear = () => {
        setSearchValue("");
        setResults([]);
        setPagination(null);
        setIsLoading(false);

        if (searchTimeout) {
            clearTimeout(searchTimeout);
            setSearchTimeout(null);
        }
    };

    return {
        search,
        browse,
        loadMore,
        clear,
        searchValue,
        setSearchValue,
        isLoading,
        isLoadingMore,
        results,
        setResults,
        pagination,
        setPagination,
    };
};

export default useDTCSearch;
