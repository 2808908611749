export enum FormComponentTypes {
    TYPOGRAPHY = "typography",
    TEXT = "text",
    SELECT = "select",
    DATE_PICKER = "datePicker",
    SWITCH = "switch",
    NUMBER = "number",
    RADIO = "radio",
    CHECKBOX = "checkbox",
    RADIO_AND_TEXT = "radioAndText",
    BOOLEAN_CARD_GROUP = "booleanCard",
    SIBLINGS_GROUP = "siblings",
    SUMMARY = "summaryTable",
    MANUAL_ENTRY = "manualEntry",
}

export enum BooleanOptionValues {
    YES = "YES",
    NO = "NO",
}

export enum BooleanOptionLabels {
    YES = "general:yes",
    NO = "general:no",
}

export enum FamilyFinancialProvideValues {
    LIMITED = "LIMITED",
    FULL = "FULL",
}

export enum FamilyFinancialProvideLabels {
    LIMITED = "Limited family and financial data",
    FULL = "Full family and financial data",
}

export enum ManualEntryTypes {
    SCHOLARSHIP = "SCHOLARSHIP",
    OUT_OF_POCKET = "OUT_OF_POCKET",
}
