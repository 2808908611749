import { DEFAULT_NET_PRICE } from "@meadow-fi/price-libraries/defaults/net-price";
import { NetPricePeriod, StandardizedTest } from "@meadow-fi/price-libraries/enums/net-price";
import { NetPriceInput } from "@meadow-fi/price-libraries/interfaces/net-price";
import { NetPriceEstimate } from "@meadow-fi/price-libraries/interfaces/net-price-estimate";
import cloneDeep from "lodash.clonedeep";
import debounce from "lodash.debounce";
import set from "lodash.set";
import { createContext, Dispatch } from "react";
import { NavigateFunction } from "react-router-dom";
import { DEBOUNCE } from "../constants/dtcSearch";
import { ActionTypes } from "../enums/all";
import { LocalStorageKeys } from "../enums/localStorage";
import { NetPriceCalculatorDataIds } from "../enums/netPriceCalculator";
import { NpcLocationData, NpcLocationGetDataUpdateKeys } from "../hooks/useNpcLocation";
import { Logging } from "../utils/Logging";

export enum NetPriceCalculatorReducerActionTypes {
    SET_INITIAL_STANDARDIZED_TEST = "SET_INITIAL_STANDARDIZED_TEST",
    SET_ESTIMATE = "SET_ESTIMATE",
    SET_LOCATION_STATE = "SET_LOCATION_STATE",
    SET_NET_PRICE_PERIOD = "SET_NET_PRICE_PERIOD",
    RESET_DIFF = "RESET_DIFF",
    SET_IS_LOADING = "SET_IS_LOADING",
    SET_SCHOOL_UNIT_ID = "SET_SCHOOL_UNIT_ID",
    CLEAR_ALL = "CLEAR_ALL",
}

export type NetPriceCalculatorReducerAction =
    | {
          type: NetPriceCalculatorReducerActionTypes.SET_INITIAL_STANDARDIZED_TEST;
          payload: StandardizedTest;
      }
    | {
          type: NetPriceCalculatorReducerActionTypes.SET_ESTIMATE;
          payload: NetPriceEstimate;
      }
    | {
          type: ActionTypes.SET_DATA;
          payload: NetPriceInput;
      }
    | {
          type: ActionTypes.UPDATE_DATA;
          payload: Partial<NetPriceInput> | { [key in NetPriceCalculatorDataIds]?: any };
          navigate?: NavigateFunction;
          getNpcLocationData?: (update?: {
              [key in NpcLocationGetDataUpdateKeys]?: any;
          }) => NpcLocationData;
      }
    | {
          type: NetPriceCalculatorReducerActionTypes.SET_LOCATION_STATE;
          payload: NpcLocationData | null;
      }
    | {
          type: NetPriceCalculatorReducerActionTypes.SET_NET_PRICE_PERIOD;
          payload: NetPricePeriod | null;
      }
    | {
          type: NetPriceCalculatorReducerActionTypes.SET_IS_LOADING;
          payload: boolean;
      }
    | {
          type: NetPriceCalculatorReducerActionTypes.SET_SCHOOL_UNIT_ID;
          payload: number;
      }
    | {
          type: NetPriceCalculatorReducerActionTypes.RESET_DIFF;
      }
    | {
          type: NetPriceCalculatorReducerActionTypes.CLEAR_ALL;
      };

export type NetPriceCalculatorReducerState = {
    data: NetPriceInput;
    estimate: NetPriceEstimate;
    netPricePeriod: NetPricePeriod | null;
    dataDiff: { key: string; value: any }[];
    isLoading: boolean;
    locationState: NpcLocationData | null;
    schoolUnitId: number | null;
};

export const netPriceCalculatorReducerInitialState: NetPriceCalculatorReducerState = {
    data: cloneDeep(DEFAULT_NET_PRICE),
    estimate: {
        costOfAttendance: {
            directCosts: {
                roomAndBoard: 1000,
                tuitionAndFees: 0,
                total: 0,
            },
            indirectCosts: {
                booksAndSupplies: 0,
                personalExpenses: 0,
                transportation: 0,
                roomAndBoard: 0,
                total: 0,
            },
            total: 0,
        },
        netPricePeriod: NetPricePeriod.ANNUAL,
        scholarshipAid: {
            meritAid: 0,
            militaryAid: {
                militaryAid: 0,
                yellowRibbonAid: 0,
                total: 0,
            },
            needBasedAid: {
                federalPellGrant: 0,
                institutionalGrant: 0,
                stateGrant: 0,
                total: 0,
            },
            total: 0,
        },
        total: 0,
    },
    netPricePeriod: NetPricePeriod.ANNUAL,
    dataDiff: [],
    isLoading: false,
    locationState: null,
    schoolUnitId: null,
};

export const NetPriceCalculatorContext = createContext<{
    state: NetPriceCalculatorReducerState;
    dispatch: Dispatch<NetPriceCalculatorReducerAction>;
}>({
    state: netPriceCalculatorReducerInitialState,
    dispatch: () => null,
});

const updatedDebounced = debounce((action: any, locationState: any) => {
    action.navigate(".", { state: locationState, replace: true });
}, DEBOUNCE);

const inputsToLocalStorageDebounced = debounce(
    (schoolUnitId: number | null, input: NetPriceInput) => {
        if (schoolUnitId) {
            const estimateInputs = JSON.parse(
                window.localStorage.getItem(LocalStorageKeys.SAVED_ESTIMATES) ?? "{}"
            );
            estimateInputs[String(schoolUnitId)] = {
                externalScholarships: { entries: [] },
                loans: { subsidized: 0, unsubsidized: 0, plus: 0, privateLoan: 0 },
                outOfPocket: { entries: [] },
                other: { workStudyHours: 0, employmentContribution: 0 },
                // add any existing default saved
                ...(estimateInputs[String(schoolUnitId)] ?? {}),
                ...input,
            };
            window.localStorage.setItem(
                LocalStorageKeys.SAVED_ESTIMATES,
                JSON.stringify(estimateInputs)
            );
        }
    },
    DEBOUNCE * 2
);

export const netPriceCalculatorReducer = (
    state: NetPriceCalculatorReducerState,
    action: NetPriceCalculatorReducerAction
) => {
    switch (action.type) {
        case NetPriceCalculatorReducerActionTypes.SET_INITIAL_STANDARDIZED_TEST: {
            const newState = { ...state };
            newState.data.merit.standardizedTest = action.payload;
            return newState;
        }
        case NetPriceCalculatorReducerActionTypes.RESET_DIFF: {
            return {
                ...state,
                dataDiff: [],
            };
        }
        case NetPriceCalculatorReducerActionTypes.SET_ESTIMATE: {
            return {
                ...state,
                estimate: action.payload,
            };
        }
        case ActionTypes.SET_DATA: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case NetPriceCalculatorReducerActionTypes.SET_LOCATION_STATE: {
            return {
                ...state,
                locationState: action.payload,
            };
        }
        case ActionTypes.UPDATE_DATA: {
            const key = Object.keys(action.payload)[0];
            const value = cloneDeep(Object.values(action.payload)[0]);

            const dataDiff = cloneDeep(state.dataDiff || []);
            dataDiff.push({ key, value });

            const updatedData: NetPriceInput = cloneDeep(set(state.data, key, value));

            if (action.navigate && action.getNpcLocationData) {
                const locationState = action.getNpcLocationData({
                    [NpcLocationGetDataUpdateKeys[key]]: action.payload,
                });
                updatedDebounced(action, locationState);
            }

            inputsToLocalStorageDebounced(state.schoolUnitId, updatedData);

            return {
                ...state,
                data: updatedData,
                dataDiff,
            };
        }
        case NetPriceCalculatorReducerActionTypes.SET_NET_PRICE_PERIOD: {
            Logging.changeNetPricePeriod(state.netPricePeriod!, action.payload!);
            return {
                ...state,
                netPricePeriod: action.payload,
            };
        }
        case NetPriceCalculatorReducerActionTypes.CLEAR_ALL: {
            return netPriceCalculatorReducerInitialState;
        }
        case NetPriceCalculatorReducerActionTypes.SET_IS_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }
        case NetPriceCalculatorReducerActionTypes.SET_SCHOOL_UNIT_ID: {
            return {
                ...state,
                schoolUnitId: action.payload,
            };
        }

        default:
            return state;
    }
};
