import {
    FamilySubStepIds,
    FinancialSubStepIds,
    PersonalSubStepIds,
    ReviewSubStepIds,
} from "../../../enums/onboarding";
import { IStepperNavigation } from "../../../interfaces/stepper";
import { AllSubStepIds } from "../../../types/onboarding";

export const ONBOARDING_STEPS_NAV: { [id in AllSubStepIds]: IStepperNavigation } = {
    [PersonalSubStepIds.BIRTHDAY_CITIZENSHIP]: {
        activeStep: 0,
        activeSubStep: PersonalSubStepIds.BIRTHDAY_CITIZENSHIP,
    },
    [PersonalSubStepIds.INFO_CARDS]: {
        activeStep: 0,
        activeSubStep: PersonalSubStepIds.INFO_CARDS,
    },
    [FamilySubStepIds.PMARITAL_SIBLINGS]: {
        activeStep: 1,
        activeSubStep: FamilySubStepIds.PMARITAL_SIBLINGS,
    },
    [FamilySubStepIds.MARITAL]: {
        activeStep: 1,
        activeSubStep: FamilySubStepIds.MARITAL,
    },
    [FamilySubStepIds.DEPENDENT]: {
        activeStep: 1,
        activeSubStep: FamilySubStepIds.DEPENDENT,
    },
    [FinancialSubStepIds.PARENT_FINANCES_1]: {
        activeStep: 2,
        activeSubStep: FinancialSubStepIds.PARENT_FINANCES_1,
    },
    [FinancialSubStepIds.PARENT_FINANCES_2]: {
        activeStep: 2,
        activeSubStep: FinancialSubStepIds.PARENT_FINANCES_2,
    },
    [FinancialSubStepIds.STUDENT_FINANCES]: {
        activeStep: 2,
        activeSubStep: FinancialSubStepIds.STUDENT_FINANCES,
    },
    [FinancialSubStepIds.EFC]: {
        activeStep: 2,
        activeSubStep: FinancialSubStepIds.EFC,
    },
    [ReviewSubStepIds.REVIEW_EFC]: {
        activeStep: 3,
        activeSubStep: ReviewSubStepIds.REVIEW_EFC,
    },
};
