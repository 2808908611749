import { NetPriceInput } from "@meadow-fi/price-libraries/interfaces/net-price";
import { Student } from "@meadow-fi/price-libraries/interfaces/student";
import axios from "axios";
import axiosRetry from "axios-retry";
import {
    CreateNetPriceResponse,
    CreateNextStepsResponse,
    CreateStudentResponse,
    GetCalculatorResponse,
    getDTCSchoolResponse,
    GetNetPriceResponse,
    GetNextStepsResponse,
    GetSchoolResponse,
    GetSchoolsResponse,
    GetStudentResponse,
    RecommendedSchoolsDTCParams,
    RecommendedSchoolsDTCResponse,
    SearchDTCParams,
    SearchDTCResponse,
} from "../types/api";
import networkCalls from "../utils/networkCalls";

const host = process.env.REACT_APP_MEADOW_BACKEND_ENDPOINT;

export const API_ROOT_URL = `${host}/meadow`;

const useApi = () => {
    const { get, put } = networkCalls();
    // By default, it retries if it is a network error or a 5xx error on an idempotent request (GET, HEAD, OPTIONS, PUT or DELETE).
    axiosRetry(axios, {
        retries: 3,
        retryDelay: (retryCount) => {
            return retryCount * 500;
        },
    });

    // DTC (DIRECT TO STUDENT SCHOOLS)
    const browseDTC = async (params: SearchDTCParams): Promise<SearchDTCResponse> => {
        const urlSearchParams = new URLSearchParams(params);
        return get(`/v1/dtc/schools?${urlSearchParams.toString()}`);
    };
    const searchDTC = async (params: SearchDTCParams): Promise<SearchDTCResponse> => {
        const urlSearchParams = new URLSearchParams(params);
        return get(`/v1/dtc/schools/search?${urlSearchParams.toString()}`);
    };
    const getDTCSchool = async (unitid: string): Promise<getDTCSchoolResponse> => {
        return get(`/v1/dtc/schools/${unitid}`);
    };
    const getDTCSchoolV2 = async (unitid: string): Promise<getDTCSchoolResponse> => {
        return get(`/v2/dtc/schools/${unitid}`);
    };
    const recommendedSchoolsDTC = async (
        params?: RecommendedSchoolsDTCParams
    ): Promise<RecommendedSchoolsDTCResponse> => {
        if (!params) {
            return get("/v1/dtc/schools/recommend");
        }
        const urlSearchParams = new URLSearchParams(params);
        return get(`/v1/dtc/schools/recommend?${urlSearchParams.toString()}`);
    };

    // NET PRICE
    const getNetPrice = async (
        schoolId: string,
        calculatorId: string
    ): Promise<GetNetPriceResponse> => {
        return get(`/v1/netprices/${schoolId}?calculatorId=${calculatorId}`, { auth: true });
    };
    const createNetPrice = async (
        schoolId: string,
        calculatorId: string,
        data: NetPriceInput
    ): Promise<CreateNetPriceResponse> => {
        data = { ...data, calculatorId };
        return put(`/v1/netprices/${schoolId}?calculatorId=${calculatorId}`, data, {
            authOptional: true,
        });
    };
    const updateNetPrice = createNetPrice;

    // NEXT STEPS
    const getNextSteps = async (schoolId: string): Promise<GetNextStepsResponse> => {
        return get(`/v1/nextsteps/${schoolId}`, { auth: true });
    };
    const updateNextSteps = async (
        schoolId: string,
        data: string[]
    ): Promise<CreateNextStepsResponse> => {
        return put(`/v1/nextsteps/${schoolId}`, data, { auth: true });
    };

    // SCHOOL
    const getSchools = async (): Promise<GetSchoolsResponse> => {
        return await get("/v1/schools");
    };
    const getSchool = async (id: string): Promise<GetSchoolResponse> => {
        return await get(`/v1/schools/${id}`);
    };
    const getSchoolV2 = async (id: string): Promise<GetSchoolResponse> => {
        return await get(`/v2/schools/${id}`);
    };

    // CALCULATOR
    const getSchoolCalculator = async (
        schoolId: string,
        calculatorId: string
    ): Promise<GetCalculatorResponse> => {
        return await get(`/v1/schools/${schoolId}/calculators/${calculatorId}`);
    };

    const getSchoolCalculatorV2 = async (
        schoolId: string,
        calculatorId: string
    ): Promise<GetCalculatorResponse> => {
        return await get(`/v2/schools/${schoolId}/calculators/${calculatorId}`);
    };

    // STUDENT
    const getStudent = async (): Promise<GetStudentResponse> => {
        return get("/v1/student", { auth: true });
    };
    const createStudent = async (data: Partial<Student>): Promise<CreateStudentResponse> => {
        return put("/v1/student", data, { authOptional: true });
    };
    const updateStudent = createStudent;

    return {
        getNetPrice,
        createNetPrice,
        updateNetPrice,
        getNextSteps,
        updateNextSteps,
        getSchools,
        getSchool,
        getSchoolV2,
        getSchoolCalculator,
        getSchoolCalculatorV2,
        getStudent,
        createStudent,
        updateStudent,
        browseDTC,
        searchDTC,
        getDTCSchool,
        getDTCSchoolV2,
        recommendedSchoolsDTC,
    };
};

export default useApi;
