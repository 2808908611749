import { DTCNetPriceEstimate } from "@meadow-fi/price-libraries/interfaces/dtc-net-price";
import { DTCNetPriceInput } from "@meadow-fi/price-libraries/interfaces/dtc-net-price-input";
import { DTCSchool } from "@meadow-fi/price-libraries/interfaces/dtc-school";
import { School } from "@meadow-fi/price-libraries/interfaces/school";
import { createContext, Dispatch } from "react";

export enum UserReducerActionTypes {
    SET_SAVED_SCHOOLS = "SET_SAVED_SCHOOLS",
    SET_SAVED_ESTIMATES = "SET_SAVED_ESTIMATES",
    SET_RECOMMENDED_SCHOOLS = "SET_RECOMMENDED_SCHOOLS",
    SET_MY_SCHOOLS = "SET_MY_SCHOOLS",
}

export type UserContextSavedEstimatesInputs = { [id: string]: DTCNetPriceInput };
export type UserContextSavedEstimates = { [id: string]: DTCNetPriceEstimate };

export type UserReducerAction =
    | {
          type: UserReducerActionTypes.SET_SAVED_ESTIMATES;
          payload: {
              id: string;
              input: DTCNetPriceInput;
              estimate: DTCNetPriceEstimate;
          };
      }
    | {
          type: UserReducerActionTypes.SET_SAVED_SCHOOLS;
          payload: number[];
      }
    | { type: UserReducerActionTypes.SET_MY_SCHOOLS; payload: School }
    | {
          type: UserReducerActionTypes.SET_RECOMMENDED_SCHOOLS;
          payload: Partial<DTCSchool>[];
      };

export type UserReducerState = {
    savedSchools: number[];
    savedEstimates: UserContextSavedEstimates | null;
    savedEstimatesInputs: UserContextSavedEstimatesInputs | null;
    recommendedSchools: Partial<DTCSchool>[];
    mySchools: School[] | null;
};

export const UserReducerInitialState: UserReducerState = {
    savedEstimatesInputs: null,
    savedSchools: [],
    savedEstimates: null,
    recommendedSchools: [],
    mySchools: null,
};

export const UserContext = createContext<{
    state: UserReducerState;
    dispatch: Dispatch<UserReducerAction>;
}>({
    state: UserReducerInitialState,
    dispatch: () => null,
});

export const userReducer = (state: UserReducerState, action: UserReducerAction) => {
    switch (action.type) {
        case UserReducerActionTypes.SET_SAVED_ESTIMATES: {
            return {
                ...state,
                savedEstimatesInputs: {
                    ...(state.savedEstimatesInputs || {}),
                    [action.payload.id]: action.payload.input,
                },
                savedEstimates: {
                    ...(state.savedEstimates || {}),
                    [action.payload.id]: action.payload.estimate,
                },
            };
        }
        case UserReducerActionTypes.SET_SAVED_SCHOOLS: {
            return { ...state, savedSchools: action.payload };
        }
        case UserReducerActionTypes.SET_RECOMMENDED_SCHOOLS: {
            return { ...state, recommendedSchools: action.payload };
        }
        case UserReducerActionTypes.SET_MY_SCHOOLS: {
            return {
                ...state,
                mySchools: [...(state.mySchools || []), action.payload],
                error: null,
                isLoading: false,
            };
        }
        default:
            return state;
    }
};
