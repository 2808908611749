import { Student } from "@meadow-fi/price-libraries/interfaces/student";
import { createContext, Dispatch } from "react";
import { DTCEstimateModalTypes } from "../enums/dtcNetPriceCalculator";

export enum ModalReducerActionTypes {
    TOGGLE_SECONDARY_COMPONENT_MODAL = "TOGGLE_SECONDARY_COMPONENT_MODAL",

    TOGGLE_RECOVER_ESTIMATE_MODAL = "TOGGLE_RECOVER_ESTIMATE_MODAL",
    SET_RECOVER_ESTIMATE_MODAL_DATA = "SET_RECOVER_ESTIMATE_MODAL_DATA",

    TOGGLE_CALCULATOR_TYPE_MODAL = "TOGGLE_CALCULATOR_TYPE_MODAL",

    TOGGLE_MOBILE_SEARCH_OVERLAY = "TOGGLE_MOBILE_SEARCH_OVERLAY",

    TOGGLE_ESTIMATE_MODAL = "TOGGLE_ESTIMATE_MODAL",
}

export type ModalReducerToggleCalculatorModalPayload = {
    open?: boolean;
    isNew?: boolean;
};

export type ModalReducerAction =
    | {
          type: ModalReducerActionTypes.TOGGLE_SECONDARY_COMPONENT_MODAL;
          payload: boolean;
      }
    | {
          type: ModalReducerActionTypes.TOGGLE_RECOVER_ESTIMATE_MODAL;
          payload: boolean;
      }
    | {
          type: ModalReducerActionTypes.SET_RECOVER_ESTIMATE_MODAL_DATA;
          payload: Student | null;
      }
    | {
          type: ModalReducerActionTypes.TOGGLE_CALCULATOR_TYPE_MODAL;
          payload: Partial<ModalReducerToggleCalculatorModalPayload>;
      }
    | {
          type: ModalReducerActionTypes.TOGGLE_MOBILE_SEARCH_OVERLAY;
          payload: { open: boolean };
      }
    | {
          type: ModalReducerActionTypes.TOGGLE_ESTIMATE_MODAL;
          payload: { open: boolean; type: DTCEstimateModalTypes | null; index?: number };
      };

export type ModalReducerState = {
    secondaryComponentModal: boolean;

    recoverEstimateModalOpen: boolean;
    recoverEstimateModalData: Student | null;

    calculatorTypeModal: { open: boolean; isNew: boolean };

    mobileSearchOverlay: { open: boolean };
    estimateModal: { open: boolean; type: DTCEstimateModalTypes | null; index?: number };
};

export const modalReducerInitialState: ModalReducerState = {
    secondaryComponentModal: false,

    recoverEstimateModalOpen: false,
    recoverEstimateModalData: null,

    calculatorTypeModal: { open: false, isNew: false },

    mobileSearchOverlay: { open: false },
    estimateModal: { open: false, type: null },
};

export const ModalContext = createContext<{
    state: ModalReducerState;
    dispatch: Dispatch<ModalReducerAction>;
}>({
    state: modalReducerInitialState,
    dispatch: () => null,
});

export const modalReducer = (
    state: ModalReducerState,
    action: ModalReducerAction
): ModalReducerState => {
    switch (action.type) {
        case ModalReducerActionTypes.TOGGLE_SECONDARY_COMPONENT_MODAL: {
            return {
                ...state,
                secondaryComponentModal: action.payload,
            };
        }

        case ModalReducerActionTypes.TOGGLE_RECOVER_ESTIMATE_MODAL: {
            return {
                ...state,
                recoverEstimateModalOpen: action.payload,
            };
        }
        case ModalReducerActionTypes.SET_RECOVER_ESTIMATE_MODAL_DATA: {
            return {
                ...state,
                recoverEstimateModalData: action.payload,
            };
        }

        case ModalReducerActionTypes.TOGGLE_CALCULATOR_TYPE_MODAL: {
            return {
                ...state,
                calculatorTypeModal: { ...state.calculatorTypeModal, ...action.payload },
            };
        }

        case ModalReducerActionTypes.TOGGLE_MOBILE_SEARCH_OVERLAY: {
            return {
                ...state,
                mobileSearchOverlay: action.payload,
            };
        }

        case ModalReducerActionTypes.TOGGLE_ESTIMATE_MODAL: {
            return {
                ...state,
                estimateModal: action.payload,
            };
        }

        default:
            return state;
    }
};
