declare module "@mui/material/Button/Button" {
    interface ButtonPropsVariantOverrides {
        primary: true;
        primaryAlternative: true;
        secondary: true;
        secondaryDTC: true;
        danger: true;
        boolCard: true;
        netPriceToggle: true;
        coverNetPriceToggle: true;
        secondaryComponent: true;
        mobileMenu: true;
    }
}

import { createTheme } from "@mui/material";
import { MEADOW_BLUE } from "../constants/general";
import {
    BACKGROUND_BLUE,
    BACKGROUND_DEFAULT,
    BLACK_500,
    BLUEBERRY_500,
    DS_V2_GREY_100,
    ERROR_100,
    GREY_20,
    GREY_50,
    PRIMARY_100,
    PRIMARY_80,
    SECONDARY_100,
    SECONDARY_20,
    SECONDARY_30,
    SECONDARY_40,
    SECONDARY_60,
    SECONDARY_70,
    SECONDARY_90,
    TYPOGRAPHY_0,
    TYPOGRAPHY_100,
    TYPOGRAPHY_20,
    TYPOGRAPHY_60,
    TYPOGRAPHY_80,
    TYPOGRAPHY_BLUE,
    WHITE,
} from "./colors";

export const BREAKPOINT_SM = 428;
export const BREAKPOINT_MD = 1028;
export const BREAKPOINT_LG = 1200;
export const BREAKPOINT_XL = 1440;

export const SIDEBAR_BREAKPOINT = "xl";
export const SIDEBAR_BREAKPOINT_VALUE = {
    lg: BREAKPOINT_LG,
    xl: BREAKPOINT_XL,
}[SIDEBAR_BREAKPOINT];

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: BREAKPOINT_SM,
            md: BREAKPOINT_MD,
            lg: BREAKPOINT_LG,
            xl: BREAKPOINT_XL,
        },
    },
    palette: {
        primary: {
            main: PRIMARY_100,
            light: PRIMARY_80,
        },
        secondary: {
            main: SECONDARY_30,
            light: SECONDARY_20,
            dark: SECONDARY_40,
        },
        background: {
            default: BACKGROUND_DEFAULT,
        },
    },
    typography: {
        fontFamily: "'Open Sans', 'Helvetica', 'Arial', sans-serif",
        h1: {
            fontSize: "3.125rem",
            color: TYPOGRAPHY_80,
            fontWeight: 800,
        },
        h2: {
            fontSize: "2.25rem",
            lineHeight: "2.5rem",
            color: TYPOGRAPHY_80,
            fontWeight: 800,
        },
        h3: {
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            color: TYPOGRAPHY_80,
            fontWeight: 700,
        },
        h4: {
            fontSize: "1.5rem",
            lineHeight: "2.25rem",
            color: TYPOGRAPHY_80,
            fontWeight: 600,
        },
        h5: {
            fontSize: "1rem",
            lineHeight: "2.25rem",
            color: TYPOGRAPHY_80,
            fontWeight: 600,
        },
        h6: {
            fontSize: "0.75rem",
            lineHeight: "1.25rem",
            color: TYPOGRAPHY_80,
            fontWeight: 600,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: TYPOGRAPHY_100,
            fontWeight: 300,
        },
        body2: {
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: TYPOGRAPHY_100,
            fontWeight: 300,
        },
        subtitle1: {
            fontSize: "0.75rem",
            lineHeight: "1.25rem",
            color: TYPOGRAPHY_100,
        },
        button: {
            fontSize: "0.875rem",
            lineHeight: "1.5rem",
            color: TYPOGRAPHY_100,
            fontWeight: 700,
        },
        caption: {
            fontSize: "0.575rem",
            lineHeight: "0.675rem",
            color: TYPOGRAPHY_100,
            fontWeight: 300,
        },
    },
    components: {
        // TYPOGRAPHY
        MuiTypography: {
            styleOverrides: {
                caption: {
                    fontSize: "0.75rem !important",
                },
                root: {
                    whiteSpace: "pre-line",
                },
            },
        },
        // BUTTONS
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "10px",
                    textTransform: "none",
                },
            },
            variants: [
                {
                    props: { variant: "primary" },
                    style: {
                        background: MEADOW_BLUE,
                        color: WHITE,
                        fontSize: "0.875rem",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        "&:disabled": {
                            backgroundColor: DS_V2_GREY_100,
                            color: BLACK_500,
                        },
                    },
                },
                {
                    props: { variant: "secondary" },
                    style: {
                        background: "transparent",
                        color: SECONDARY_100,
                        fontWeight: 600,
                        fontSize: "1.125rem",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    },
                },
                {
                    props: { variant: "secondaryDTC" },
                    style: {
                        background: BLUEBERRY_500,
                        color: TYPOGRAPHY_0,
                        fontStyle: "normal",
                        fontSize: "1rem",
                        fontWeight: 700,
                        lineHeight: "17px",
                        padding: "12px 20px",
                        borderRadius: "48px",
                        "&:hover": {
                            background: BLUEBERRY_500,
                            opacity: 0.8,
                        },
                    },
                },
                {
                    props: { variant: "danger" },
                    style: {
                        background: "transparent",
                        color: ERROR_100,
                        fontWeight: 600,
                        fontSize: "1.125rem",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    },
                },
                {
                    props: { variant: "mobileMenu" },
                    style: {
                        background: "transparent",
                        color: TYPOGRAPHY_0,
                        fontWeight: 600,
                        fontSize: "1.125rem",
                        width: "100%",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        borderRadius: 0,
                    },
                },
                {
                    props: { variant: "boolCard" },
                    style: {
                        backgroundColor: BACKGROUND_BLUE,
                        color: SECONDARY_60,
                        paddingTop: "16px",
                        paddingBottom: "10px",
                        boxShadow: "4px 2px 6px rgba(0, 0, 0, 0.1)",
                        fontWeight: 400,
                        "&.-selected": {
                            backgroundColor: SECONDARY_60,
                            color: TYPOGRAPHY_0,
                        },
                    },
                },
                {
                    props: { variant: "netPriceToggle" },
                    style: {
                        background: "transparent",
                        color: SECONDARY_100,
                        fontWeight: 600,
                        fontSize: "0.75rem",
                        lineHeight: "1rem",
                        "&.-selected": {
                            backgroundColor: SECONDARY_100,
                            color: TYPOGRAPHY_0,
                        },
                    },
                },
                {
                    props: { variant: "coverNetPriceToggle" },
                    style: {
                        fontSize: "1.125rem",
                        padding: "10px 0",
                        background: "transparent",
                        color: SECONDARY_60,
                        fontWeight: 700,
                        "&.-selected": {
                            background: TYPOGRAPHY_BLUE,
                            color: TYPOGRAPHY_0,
                        },
                    },
                },
                {
                    props: { variant: "secondaryComponent" },
                    style: {
                        background: `linear-gradient(211.94deg, ${SECONDARY_90} 33.48%, ${SECONDARY_70} 80.8%)`,
                        color: TYPOGRAPHY_0,
                        fontWeight: 700,
                        fontSize: "1rem",
                        width: "100vw",
                        height: 64,
                        borderRadius: 0,
                        justifyContent: "space-between",
                        padding: "0 32px",
                    },
                },
            ],
        },
        // FORM
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                    fontWeight: 700,
                },
            },
            defaultProps: {
                focused: false,
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: "0",
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    mt: 1,
                    fontSize: "0.75rem",
                    lineHeight: "1.125rem",
                },
            },
        },
        MuiInput: {
            defaultProps: {
                color: "secondary",
            },
        },
        MuiFilledInput: {
            defaultProps: {
                color: "secondary",
            },
            styleOverrides: {
                root: {
                    backgroundColor: GREY_20,
                    ":hover": {
                        backgroundColor: GREY_20,
                    },
                    ":focus": {
                        backgroundColor: GREY_20,
                    },
                    ":focus-within": {
                        backgroundColor: GREY_20,
                    },
                },
                input: {
                    fontSize: "0.875rem",
                    paddingTop: "16px",
                    paddingBottom: "12px",
                    "&::placeholder": {
                        color: TYPOGRAPHY_60,
                    },
                    ":disabled": {
                        backgroundColor: GREY_50,
                        color: BLACK_500,
                        WebkitTextFillColor: BLACK_500,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: GREY_20,
                    ":before": {
                        borderBottom: "none !important",
                    },
                    "&:not(.Mui-disabled)::before": {
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                    },
                    "&.lang-select div": {
                        backgroundColor: BACKGROUND_DEFAULT,
                        fontSize: "1.25rem",
                    },
                    // these are specific styling for screen readers on the language select
                    "&.lang-select": {
                        ".MuiOutlinedInput-notchedOutline > legend": {
                            width: "0",
                        },
                        ".MuiOutlinedInput-notchedOutline > legend > span": {
                            width: "0",
                        },
                        "&:hover": {
                            ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                        },
                    },
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                color: "secondary",
                variant: "filled",
            },
            styleOverrides: {
                select: {
                    backgroundColor: GREY_20,
                    ".lang-text": {
                        display: "none",
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    ".lang-text": {
                        fontSize: "0.875rem",
                        marginLeft: "6px",
                        fontWeight: 500,
                        color: TYPOGRAPHY_80,
                    },
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                color: "secondary",
            },
            styleOverrides: {
                root: {
                    "&.next-steps": {
                        width: "48px",
                        height: "48px",
                        backgroundColor: SECONDARY_60,
                        borderRadius: "10px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                        svg: {
                            color: TYPOGRAPHY_0,
                        },
                    },
                },
                colorSecondary: {
                    color: SECONDARY_30,
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                color: "secondary",
            },
            styleOverrides: {
                colorSecondary: {
                    color: SECONDARY_30,
                },
            },
        },
        MuiSwitch: {
            defaultProps: {
                color: "secondary",
            },
            styleOverrides: {
                root: {
                    height: "56px",
                    width: "80px",
                },
                track: {
                    height: "35px",
                    borderRadius: "20px",
                },
                switchBase: {
                    padding: "0.85rem",
                },
                thumb: {
                    height: "30px",
                    width: "30px",
                },
            },
        },
        MuiSlider: {
            defaultProps: {
                color: "secondary",
            },
        },

        // STEPPER
        MuiStepper: {
            defaultProps: {},
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontSize: "0.833rem",
                    lineHeight: "2.25rem",
                    color: TYPOGRAPHY_20,
                    fontWeight: 600,
                    paddingLeft: "20px",
                    "&.Mui-completed": {
                        fontSize: "0.833rem",
                        lineHeight: "2.25rem",
                        color: TYPOGRAPHY_20,
                        fontWeight: 600,
                    },
                    "&.Mui-active": {
                        "@media (max-width: 768px)": {
                            lineHeight: "1rem",
                        },
                        fontSize: "0.833rem",
                        lineHeight: "2.25rem",
                        color: TYPOGRAPHY_100,
                        fontWeight: 600,
                    },
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    height: "27px",
                    width: "27px",
                    fill: SECONDARY_40,
                    "&.Mui-completed": {
                        fill: SECONDARY_40,
                    },
                },
                text: {
                    fill: TYPOGRAPHY_0,
                    fontWeight: 600,
                },
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    "@media (max-width: 768px)": {
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                    },
                    borderTopWidth: 0,
                },
            },
        },

        // PROGRESS BAR
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: "9px",
                    borderRadius: "5px",
                    backgroundColor: GREY_20,
                },
            },
        },
    },
});

export default theme;
